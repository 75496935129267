import { inject, injectable } from 'inversify';
import { action, comparer, computed, makeObservable, observable, reaction } from 'mobx';

import {
  IDeletePostPayload,
  IFetchPostPayload,
  IPostCreateParams,
  ITogglePlayerPostBookmarkPayload,
  ITogglePostBookmarkPayload,
  IToggleTeamPostBookmarkPayload,
} from 'services/posts/interfaces/create-post-payload.interface';
import { IPollAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';
import {
  IDeletePostResponse,
  IPostResponse,
  IVideoResponse,
} from 'services/posts/interfaces/posts-response.interface';
import { IPublicationUpdatePayload } from 'services/posts/interfaces/publication-payload.interface';
import { PostsService } from 'services/posts/posts.service';
import { ReportsService } from 'services/reports/reports.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { ApplicationStore } from 'stores/application/application.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { PlayerFeedStore } from 'stores/feeds/player-feed.store';
import { TeamFeedStore } from 'stores/feeds/team-feed.store';
import { YourFeedStore } from 'stores/feeds/your-feed.store';

import { TYPES } from 'configs/di-types.config';
import { customFormData } from 'helpers/custom-form-data.util';

import { postsLogger } from 'loggers/posts.logger';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import { pollAdapter } from './adapters/poll-adapter.util';
import { publicationAdapter, reactionsAdapter } from './adapters/publication-adapter.util';
import {
  IFormattedReactions,
  IPlayerPostVoteData,
  IPost,
  IPostVoteData,
  ITeamPostVoteData,
} from './interfaces/post.interface';
import { IPostTag } from './interfaces/post-tag.interface';
import { NBA_FEED_ID } from './posts.config';

type FeedsList = YourFeedStore | TeamFeedStore | PlayerFeedStore;

@injectable()
export class PostsStore extends ApiConnectedStore {
  private readonly applicationStore: ApplicationStore;

  private readonly feedFiltersStore: FeedFiltersStore;

  private readonly postsService: PostsService;

  private readonly reportsService: ReportsService;

  private readonly yourFeedStore: YourFeedStore;

  private readonly playerFeedStore: PlayerFeedStore;

  private readonly teamFeedStore: TeamFeedStore;

  public isLoadMore: boolean;

  public post: Maybe<IPost>;

  public posts: IPost[];

  public processingCreatePost: boolean;

  public currentPostId: Maybe<string>;

  public postTags: IPostTag[];

  public postVideo: Maybe<IVideoResponse>;

  public isPostVideoLoading: boolean;

  public isIdInvalid: boolean;

  public isPostCreationModalVisible: boolean;

  public isSubmittedContentReport: boolean;

  constructor(
    @inject(TYPES.FeedFiltersStore) feedFiltersStore: FeedFiltersStore,
    @inject(TYPES.ApplicationStore) applicationStore: ApplicationStore,
    @inject(TYPES.YourFeedStore) yourFeedStore: YourFeedStore,
    @inject(TYPES.PlayerFeedStore) playerFeedStore: PlayerFeedStore,
    @inject(TYPES.TeamFeedStore) teamFeedStore: TeamFeedStore,
    @inject(TYPES.ReportsService) reportsService: ReportsService,
    @inject(TYPES.PostsService) postsService: PostsService,
  ) {
    super();

    this.applicationStore = applicationStore;

    this.yourFeedStore = yourFeedStore;

    this.playerFeedStore = playerFeedStore;

    this.teamFeedStore = teamFeedStore;

    this.postsService = postsService;

    this.feedFiltersStore = feedFiltersStore;

    this.reportsService = reportsService;

    this.isLoadMore = false;

    this.post = null;

    this.posts = [];

    this.postVideo = null;

    this.isPostVideoLoading = false;

    this.currentPostId = null;

    this.processingCreatePost = false;

    this.isIdInvalid = false;

    this.postTags = [];

    this.isPostCreationModalVisible = false;

    this.isSubmittedContentReport = false;

    makeObservable(this, {
      isIdInvalid: observable,
      isPostVideoLoading: observable,
      isPostCreationModalVisible: observable,
      isSubmittedContentReport: observable,
      processingCreatePost: observable,
      currentPostId: observable,
      postVideo: observable,
      postTags: observable,
      post: observable,
      posts: observable,
      isLoadMore: observable,

      fetchingState: computed,
      fetchedState: computed,
      isLastPageState: computed,

      setIsIdInvalid: action.bound,
      getPostTags: action.bound,
      setProcessingCreatePost: action.bound,
      setCurrentPostId: action.bound,
      setIsPostVideoLoading: action.bound,
      setIsPostCreationModalVisible: action.bound,
      setPostVideo: action.bound,
      setPostTags: action.bound,
      setSubmittedContentReport: action.bound,
      setPost: action.bound,
      setPosts: action.bound,
      setLoadMore: action.bound,
    });

    reaction(
      () =>
        JSON.stringify(
          [this.yourFeedStore.entries, this.teamFeedStore.entries, this.playerFeedStore.entries]
            .flat()
            .map((post) => post.uuid),
        ),
      () => {
        this.syncFeedFetchedEntries();
      },
      {
        equals: comparer.shallow,
      },
    );

    reaction(
      () => this.feedFiltersStore.activeFeed,
      () => this.syncFeedFetchedEntries(),
    );
  }

  private syncFeedFetchedEntries() {
    switch (this.feedFiltersStore.activeFeed) {
      case FeedTypes.YourFeed:
        this.setPosts(this.yourFeedStore.entries);

        break;
      case FeedTypes.Team:
        this.setPosts(this.teamFeedStore.entries);

        break;
      case FeedTypes.Player:
        this.setPosts(this.playerFeedStore.entries);

        break;
      default:
        this.setPosts([]);

        break;
    }
  }

  private getCurrentFeedInstance() {
    const feedStores: Record<FeedTypes, Maybe<FeedsList>> = {
      [FeedTypes.YourFeed]: this.yourFeedStore,
      [FeedTypes.Team]: this.teamFeedStore,
      [FeedTypes.Player]: this.playerFeedStore,
      [FeedTypes.Headlines]: null,
    };

    const { activeFeed } = this.feedFiltersStore;

    if (activeFeed) {
      return feedStores[activeFeed];
    }

    return null;
  }

  private setFeedEntries(entries: IPost[]) {
    this.setPosts(entries);

    const currentFeed = this.getCurrentFeedInstance();

    currentFeed?.setEntries(entries);
  }

  public get fetchingState() {
    const currentFeed = this.getCurrentFeedInstance();

    return currentFeed?.fetching;
  }

  public get fetchedState() {
    const currentFeed = this.getCurrentFeedInstance();

    return currentFeed?.fetched;
  }

  public get isLastPageState() {
    const currentFeed = this.getCurrentFeedInstance();

    return currentFeed?.isLastPage;
  }

  public setLoadMore(isLoadMore: boolean) {
    this.isLoadMore = isLoadMore;
  }

  public setPost(post: Maybe<IPost>) {
    this.post = post;
  }

  public setPosts(posts: IPost[]) {
    this.posts = [...posts];
  }

  public setIsIdInvalid(isIdInvalid: boolean) {
    this.isIdInvalid = isIdInvalid;
  }

  public setCurrentPostId(postId: Maybe<string>) {
    this.currentPostId = postId;
  }

  public setProcessingCreatePost(value: boolean) {
    this.processingCreatePost = value;
  }

  public setIsPostCreationModalVisible(isPostCreationModalVisible: boolean) {
    this.isPostCreationModalVisible = isPostCreationModalVisible;
  }

  public setSubmittedContentReport(isSubmittedContentReport: boolean) {
    this.isSubmittedContentReport = isSubmittedContentReport;
  }

  public setIsPostVideoLoading(value: boolean) {
    this.isPostVideoLoading = value;
  }

  public setPostTags(tags: IPostTag[]) {
    this.postTags = tags;
  }

  public setPostVideo(value: Maybe<IVideoResponse>) {
    this.postVideo = value;
  }

  public async fetchToLoadMorePosts() {
    const currentFeed = this.getCurrentFeedInstance();

    this.setLoadMore(true);

    await currentFeed?.fetchNext();

    this.setLoadMore(false);
  }

  public async createPost(post: IPostCreateParams): Promise<boolean> {
    const { feedToPublish, ...postData } = post;

    this.setProcessingCreatePost(true);

    const response =
      feedToPublish === NBA_FEED_ID
        ? await this.postsService.createPost(postData)
        : await this.postsService.createTeamPost(feedToPublish, postData);

    if (response.success) {
      const currentFeed = this.getCurrentFeedInstance();

      currentFeed?.forceFetchToRefresh();

      this.setProcessingCreatePost(false);
      this.setPostVideo(null);

      return true;
    }

    if (response.code === 500 || response.code === 504) {
      const error: INotificationMessage = {
        message: 'We were unable to create your post at this time. Please try again later',
      };

      this.setErrors([error]);

      return false;
    }

    this.setErrors(response.errors);
    this.setProcessingCreatePost(false);

    return false;
  }

  public async fetchRegularPostById(payload: IFetchPostPayload) {
    this.setIsIdInvalid(false);

    const { teamId, playerId, postId } = payload;

    let response: IResponse<IPostResponse>;

    if (teamId) {
      response = await this.postsService.fetchTeamPostById(teamId, postId);
    } else if (playerId) {
      response = await this.postsService.fetchPlayerPostById(playerId, postId);
    } else {
      response = await this.postsService.fetchPostById(postId);
    }

    if (response.success) {
      const postData = <IPost>publicationAdapter(response.data);

      this.setPost(postData);

      return postData;
    }

    if (response.code === 404 || response.code === 400) {
      this.setIsIdInvalid(true);
    }

    return null;
  }

  public async sendPostReport(postId: string, reasonId: number): Promise<boolean> {
    this.setErrors([]);
    this.setSubmittedContentReport(false);

    const response = await this.reportsService.sendPostReport(postId, reasonId);

    if (!response.success) {
      this.setErrors(response.errors);
    }

    this.setSubmittedContentReport(true);

    return response.success;
  }

  public async sendTeamPostReport(
    teamId: number,
    postId: string,
    reasonId: number,
  ): Promise<boolean> {
    this.setErrors([]);
    this.setSubmittedContentReport(false);

    const response = await this.reportsService.sendTeamPostReport(teamId, postId, reasonId);

    if (!response.success) {
      this.setErrors(response.errors);
    }

    this.setSubmittedContentReport(true);

    return response.success;
  }

  public async sendPlayerPostReport(
    playerId: number,
    postId: string,
    reasonId: number,
  ): Promise<boolean> {
    this.setErrors([]);
    this.setSubmittedContentReport(false);

    const response = await this.reportsService.sendPlayerPostReport(playerId, postId, reasonId);

    if (!response.success) {
      this.setErrors(response.errors);
    }

    this.setSubmittedContentReport(true);

    return response.success;
  }

  public async forceFetchToRefreshFeed() {
    const currentFeed = this.getCurrentFeedInstance();

    await currentFeed?.forceFetchToRefresh();
  }

  public async togglePostBookmark(payload: ITogglePostBookmarkPayload): Promise<void> {
    const { id } = payload;

    const response = await this.postsService.togglePostBookmark(payload);

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === id) {
          return {
            ...post,
            isBookmarked: response.data.is_bookmarked,
            bookmarksCount: response.data.bookmarks_count,
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);
    }
  }

  public async toggleTeamPostBookmark(payload: IToggleTeamPostBookmarkPayload): Promise<void> {
    const response = await this.postsService.toggleTeamPostBookmark(payload);

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === payload.postId) {
          return {
            ...post,
            isBookmarked: response.data.is_bookmarked,
            bookmarksCount: response.data.bookmarks_count,
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);
    }
  }

  public async togglePlayerPostBookmark(payload: ITogglePlayerPostBookmarkPayload): Promise<void> {
    const response = await this.postsService.togglePlayerPostBookmark(payload);

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === payload.postId) {
          return {
            ...post,
            isBookmarked: response.data.is_bookmarked,
            bookmarksCount: response.data.bookmarks_count,
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);
    }
  }

  public async postPollVote(payload: IPollVoteData): Promise<void> {
    const response = await this.postsService.postPollVote(payload);

    if (response.success) {
      if (this.posts.length) {
        this.updatePostsFeedWithPollData(payload.pollId, response.data);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  protected updatePostsFeedWithPollData(
    pollId: string,
    pollAttachmentData: IPollAttachmentResponse,
  ): void {
    const postId = this.posts
      .filter((item) => !!item.attachments)
      .find((post) => post.attachments.poll && post.attachments?.poll.uuid === pollId)?.uuid;

    const updatedPosts = this.posts.map((post) => {
      if (post.uuid === postId) {
        return <IPost>{
          ...post,
          attachments: {
            ...post.attachments,
            poll: pollAdapter(pollAttachmentData),
          },
        };
      }

      return post;
    });

    this.setFeedEntries(updatedPosts);
  }

  public async postVoteUp(payload: IPostVoteData): Promise<Maybe<IFormattedReactions>> {
    const { postId } = payload;

    const response = await this.postsService.postVoteUp(postId, {
      reaction: payload.reaction,
    });

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...reactionsAdapter(response.data),
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return reactionsAdapter(response.data);
    }

    this.setErrors(response.errors);

    return null;
  }

  public async postVoteDown(payload: IPostVoteData): Promise<Maybe<IFormattedReactions>> {
    const { postId } = payload;

    const response = await this.postsService.postVoteDown(postId, {
      reaction: payload.reaction,
    });

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...reactionsAdapter(response.data),
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return reactionsAdapter(response.data);
    }

    this.setErrors(response.errors);

    return null;
  }

  public async teamPostVoteUp(payload: ITeamPostVoteData): Promise<Maybe<IFormattedReactions>> {
    const { postId, teamId } = payload;

    const response = await this.postsService.teamPostVoteUp(postId, teamId, {
      reaction: payload.reaction,
    });

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...reactionsAdapter(response.data),
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return reactionsAdapter(response.data);
    }

    this.setErrors(response.errors);

    return null;
  }

  public async teamPostVoteDown(payload: ITeamPostVoteData): Promise<Maybe<IFormattedReactions>> {
    const { postId, teamId } = payload;

    const response = await this.postsService.teamPostVoteDown(postId, teamId, {
      reaction: payload.reaction,
    });

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...reactionsAdapter(response.data),
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return reactionsAdapter(response.data);
    }

    this.setErrors(response.errors);

    return null;
  }

  public async playerPostVoteUp(payload: IPlayerPostVoteData): Promise<Maybe<IFormattedReactions>> {
    const { postId, playerId } = payload;

    const response = await this.postsService.playerPostVoteUp(postId, playerId, {
      reaction: payload.reaction,
    });

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...reactionsAdapter(response.data),
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return reactionsAdapter(response.data);
    }

    this.setErrors(response.errors);

    return null;
  }

  public async playerPostVoteDown(
    payload: IPlayerPostVoteData,
  ): Promise<Maybe<IFormattedReactions>> {
    const { postId, playerId } = payload;

    const response = await this.postsService.playerPostVoteDown(postId, playerId, {
      reaction: payload.reaction,
    });

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...reactionsAdapter(response.data),
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return reactionsAdapter(response.data);
    }

    this.setErrors(response.errors);

    return null;
  }

  public async asyncUpdatePostById(payload: IPublicationUpdatePayload): Promise<void> {
    const { postId, teamId, playerId, commentsSort } = payload;

    const response = await this.fetchRegularPostById({
      postId,
      teamId: teamId || null,
      playerId: playerId || null,
      commentsSort,
    });

    if (response && this.posts.length) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === response.uuid) {
          return response;
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);
    }
  }

  public async getPostTags(): Promise<void> {
    const response = await this.postsService.fetchTags();

    if (response.success) {
      this.setPostTags(
        response.data.map((tag) => ({
          uuid: tag.uuid,
          name: tag.name,
          slug: tag.slug,
          dateCreated: tag.date_created,
          isAutotag: tag.is_autotag,
        })),
      );
    }
  }

  public updatePostShareCount(id: string, sharesCount: number) {
    const updatedPosts = this.posts.map((post) => {
      if (post.uuid === id) {
        return {
          ...post,
          sharesCount,
        };
      }

      return post;
    });

    this.setFeedEntries(updatedPosts);
  }

  public getPostById(id: string): Maybe<IPost> {
    const currentPost = this.posts.find((post) => post.uuid === id);

    if (currentPost) {
      return currentPost;
    }

    return null;
  }

  public async uploadVideoForPost(payload: File): Promise<void> {
    this.setIsPostVideoLoading(true);

    const formData = await customFormData(
      payload,
      'file',
      'base64File',
      this.applicationStore.isNativeApp,
    );

    postsLogger.debug({ msg: 'video form data', formData });

    const response = await this.postsService.uploadVideo(formData);

    if (response.success) {
      this.setPostVideo({
        uuid: response.data.uuid,
        url: response.data.url,
      });
    } else {
      this.setErrors(response.errors);
    }

    this.setIsPostVideoLoading(false);
  }

  public removePostFromEntries(postId: string) {
    const updatedPosts = this.posts.filter((post) => post.uuid !== postId);

    this.setFeedEntries(updatedPosts);
  }

  public async deletePost(payload: IDeletePostPayload): Promise<boolean> {
    let response: IResponse<IDeletePostResponse>;

    if (payload.teamId) {
      response = await this.postsService.deleteTeamPost(payload);
    } else {
      response = await this.postsService.deletePost(payload);
    }

    if (response.success) {
      this.removePostFromEntries(response.data.uuid);

      return true;
    }

    return false;
  }
}
