export const TYPES = {
  ApiConnectedStore: Symbol.for('ApiConnectedStore'),
  ApplicationStore: Symbol.for('ApplicationStore'),
  ApplicationService: Symbol.for('ApplicationService'),
  AuthService: Symbol.for('AuthService'),
  AuthStore: Symbol.for('AuthStore'),
  BookmarksStore: Symbol.for('BookmarksStore'),
  BookmarksService: Symbol.for('BookmarksService'),
  CommentsStore: Symbol.for('CommentsStore'),
  CommentsService: Symbol.for('CommentsService'),
  ContentCardsStore: Symbol.for('ContentCardsStore'),
  ContentCardsService: Symbol.for('ContentCardsService'),
  EventsService: Symbol.for('EventsService'),
  FeedFiltersStore: Symbol.for('FeedFiltersStore'),
  FeedFiltersService: Symbol.for('FeedFiltersService'),
  FollowStore: Symbol.for('FollowStore'),
  FollowService: Symbol.for('FollowService'),
  GalleryStore: Symbol.for('GalleryStore'),
  GameStore: Symbol.for('GameStore'),
  GlobalStatsStore: Symbol.for('GlobalStatsStore'),
  GameService: Symbol.for('GameService'),
  GameChatStore: Symbol.for('GameChatStore'),
  GameEventsStore: Symbol.for('GameEventsStore'),
  GameChatService: Symbol.for('GameChatService'),
  GamesDetailedService: Symbol.for('GamesDetailedService'),
  HttpService: Symbol.for('HttpService'),
  IoStore: Symbol.for('IoStore'),
  LocalDatabaseService: Symbol.for('LocalDatabaseService'),
  LayoutStore: Symbol.for('LayoutStore'),
  LoginWithAppleService: Symbol.for('LoginWithAppleService'),
  LoginWithGoogleService: Symbol.for('LoginWithGoogleService'),
  MetaTagsService: Symbol.for('MetaTagsService'),
  MetaTagsStore: Symbol.for('MetaTagsStore'),
  MirroredPostsService: Symbol.for('MirroredPostsService'),
  MirroredPostsStore: Symbol.for('MirroredPostsStore'),
  NewsService: Symbol.for('NewsService'),
  NewsStore: Symbol.for('NewsStore'),
  YourFeedStore: Symbol.for('YourFeedStore'),
  HeadlinesService: Symbol.for('HeadlinesService'),
  HeadlinesStore: Symbol.for('HeadlinesStore'),
  PlayerPerformanceStore: Symbol.for('PlayerPerformanceStore'),
  PlayerStore: Symbol.for('PlayerStore'),
  PlayerFeedStore: Symbol.for('PlayerFeedStore'),
  PostsStore: Symbol.for('PostsStore'),
  PlayerService: Symbol.for('PlayerService'),
  PostsService: Symbol.for('PostsService'),
  PushNotificationService: Symbol.for('PushNotificationService'),
  PlayerRankingsService: Symbol.for('PlayerRankingsService'),
  PlayerRankingsStore: Symbol.for('PlayerRankingsStore'),
  ReportsService: Symbol.for('ReportsService'),
  ScoreboardStore: Symbol.for('ScoreboardStore'),
  SearchService: Symbol.for('SearchService'),
  SearchStore: Symbol.for('SearchStore'),
  SeasonsService: Symbol.for('SeasonsService'),
  SeasonsStore: Symbol.for('SeasonsStore'),
  SettingsStore: Symbol.for('SettingsStore'),
  SettingsService: Symbol.for('SettingsService'),
  SignInService: Symbol.for('SignInService'),
  SignInStore: Symbol.for('SignInStore'),
  SignUpService: Symbol.for('SignUpService'),
  SignUpStore: Symbol.for('SignUpStore'),
  ShareStore: Symbol.for('ShareStore'),
  SplitsGamesStore: Symbol.for('SplitsGamesStore'),
  StandingsService: Symbol.for('StandingsService'),
  StandingsStore: Symbol.for('StandingsStore'),
  StatisticsService: Symbol.for('StatisticsService'),
  StatisticsStore: Symbol.for('StatisticsStore'),
  StorageService: Symbol.for('StorageService'),
  TeamsStore: Symbol.for('TeamsStore'),
  TeamFeedStore: Symbol.for('TeamFeedStore'),
  TeamsService: Symbol.for('TeamsService'),
  TeamsStatsStore: Symbol.for('TeamsStatsStore'),
  TeamsStatsService: Symbol.for('TeamsStatsService'),
};
