import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { EmptyState } from 'components/empty-state/empty-state.component';

import styles from './feed-empty-state.module.less';

interface IFeedEmptyStateProps {
  isPlayerFeed: boolean;
  onOpenFilters: () => void;
  onPlayerProfileClick: () => void;
}

export const FeedEmptyState: FC<IFeedEmptyStateProps> = memo((props: IFeedEmptyStateProps) => {
  const { isPlayerFeed, onOpenFilters, onPlayerProfileClick } = props;

  const emptyStateContent = useMemo(() => {
    const content = {
      title: 'No results by Filters',
      message:
        'It seems like there are no results for the filter set you selected. Edit filters and turn ON something.',
      btnText: 'Edit Filters',
      handleClick: onOpenFilters,
    };

    if (isPlayerFeed) {
      content.title = 'No posts yet';
      content.message = "You can find more up-to-date information on the player's Profile Page";
      content.btnText = 'View Player Profile';
      content.handleClick = onPlayerProfileClick;
    }

    return content;
  }, [isPlayerFeed, onOpenFilters, onPlayerProfileClick]);

  const feedEmptyStateClasses = useMemo(
    () =>
      cn(styles.FeedEmptyState, {
        [styles['FeedEmptyState--player-feed']]: isPlayerFeed,
      }),
    [isPlayerFeed],
  );

  return (
    <div className={feedEmptyStateClasses}>
      <EmptyState
        message={emptyStateContent.message}
        title={emptyStateContent.title}
        btnText={emptyStateContent.btnText}
        onBtnClick={emptyStateContent.handleClick}
      />
    </div>
  );
});
