import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { ContentCardsService } from 'services/content-cards/content-cards.service';
import { ContentCardsType } from 'services/content-cards/enums/content-cards-type.enum';
import {
  IGameContentDataResponse,
  IGameContentResponse,
  IPlayerContentDataResponse,
  IPlayerContentResponse,
} from 'services/content-cards/interfaces/content-cards-response.interface';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { IoStore } from 'stores/io/io.store';

import { TYPES } from 'configs/di-types.config';

import {
  gameContentAdapter,
  gameContentCardsAdapter,
  playerContentAdapter,
  playerContentCardsAdapter,
} from './adapters/publication-adapter.util';
import { IGameContent, IPlayerContent } from './interfaces/content-cards.interface';

const HOME_PLAYERS_PAGINATION_LINIT = 3;
const HOME_GAME_PAGINATION_LINIT = 1;

@injectable()
export class ContentCardsStore extends ApiConnectedStore {
  private readonly ioStore: IoStore;

  private readonly contentCardsService: ContentCardsService;

  private readonly feedFiltersStore: FeedFiltersStore;

  public fetchedGames: boolean;

  public fetchedPlayers: boolean;

  public gameContentCards: IGameContent[];

  public playerContentCards: IPlayerContent[];

  public isEnabledRealtimeConnections: boolean;

  constructor(
    @inject<IoStore>(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.ContentCardsService) contentCardsService: ContentCardsService,
    @inject(TYPES.FeedFiltersStore) feedFiltersStore: FeedFiltersStore,
  ) {
    super();

    this.ioStore = ioStore;

    this.contentCardsService = contentCardsService;

    this.feedFiltersStore = feedFiltersStore;

    this.fetchedGames = false;

    this.fetchedPlayers = false;

    this.gameContentCards = [];

    this.playerContentCards = [];

    this.isEnabledRealtimeConnections = true;

    makeObservable(this, {
      isEnabledRealtimeConnections: observable,
      fetchedGames: observable,
      fetchedPlayers: observable,
      gameContentCards: observable,
      playerContentCards: observable,

      setEnabledRealtimeConnections: action.bound,
      setFetchedGames: action.bound,
      setFetchedPlayers: action.bound,
      setGameContentCards: action.bound,
      setPlayerContentCards: action.bound,
    });

    reaction(() => this.feedFiltersStore.activeFeed, this.retrieveContentCards);

    reaction(() => this.isEnabledRealtimeConnections, this.updateRealtimeConnections);

    reaction(() => this.ioStore.socket, this.refreshContentCards);
  }

  public setFetchedGames(fetchedGames: boolean) {
    this.fetchedGames = fetchedGames;
  }

  public setFetchedPlayers(fetchedPlayers: boolean) {
    this.fetchedPlayers = fetchedPlayers;
  }

  public setEnabledRealtimeConnections(isEnabledRealtimeConnections: boolean) {
    this.isEnabledRealtimeConnections = isEnabledRealtimeConnections;
  }

  public setGameContentCards(gameContentCards: IGameContent[]) {
    this.gameContentCards = gameContentCards;
  }

  public setPlayerContentCards(playerContentCards: IPlayerContent[]) {
    this.playerContentCards = playerContentCards;
  }

  private joinToGameContentCardsRoom() {
    const gameIds = this.gameContentCards.map((content) => content.gameId);

    gameIds.forEach((gameId) => {
      const roomName = `games:id:${gameId}:content-card`;

      this.ioStore.socket.emit('join', { room: roomName });
      this.ioStore.socket.on(roomName, (response: IGameContentDataResponse) => {
        this.updateGameContentCards(response, this.gameContentCards);
      });
    });
  }

  private joinToPlayerContentCardsRoom() {
    const items = this.playerContentCards.map((content) => ({
      gameId: content.gameId,
      playerSlug: content.slug,
    }));

    items.forEach((item) => {
      const roomName = `games:id:${item.gameId}:players:slug:${item.playerSlug}:content-card`;

      this.ioStore.socket.emit('join', { room: roomName });
      this.ioStore.socket.on(roomName, (response: IPlayerContentDataResponse) => {
        this.updatePlayerContentCards(response, this.playerContentCards);
      });
    });
  }

  private updateGameContentCards(
    gameContentCardResponse: IGameContentDataResponse,
    gameContentCards: IGameContent[],
  ) {
    const enteringGameContentCard = gameContentAdapter(gameContentCardResponse);

    const updatedGameContentCards = gameContentCards.map((item) => {
      if (enteringGameContentCard.gameId === item.gameId) {
        return enteringGameContentCard;
      }

      return item;
    });

    this.setGameContentCards(updatedGameContentCards);
  }

  private updatePlayerContentCards(
    playerContentCardResponse: IPlayerContentDataResponse,
    playerContentCards: IPlayerContent[],
  ) {
    const enteringPlayerContentCard = playerContentAdapter(playerContentCardResponse);

    const updatedPlayerContentCards = playerContentCards.map((item) => {
      if (enteringPlayerContentCard.playerId === item.playerId) {
        return enteringPlayerContentCard;
      }

      return item;
    });

    this.setPlayerContentCards(updatedPlayerContentCards);
  }

  private async fetchContentCards() {
    const { activeFeed } = this.feedFiltersStore;

    if (FeedTypes.YourFeed === activeFeed) {
      await this.fetchPlayerContentCards();
      await this.fetchGameContentCards();
    }
  }

  public retrieveContentCards = async () => {
    this.reset();

    await this.fetchContentCards();

    this.joinToGameContentCardsRoom();
    this.joinToPlayerContentCardsRoom();
  };

  public refreshContentCards = async () => {
    this.leaveFromGameContentCardsRoom();
    this.leaveFromPlayerContentCardsRoom();

    await this.fetchContentCards();

    this.joinToGameContentCardsRoom();
    this.joinToPlayerContentCardsRoom();
  };

  public async fetchGameContentCards() {
    this.setFetchedGames(false);

    const response = await this.contentCardsService.fetchContentCards<IGameContentResponse[]>(
      {
        limit: HOME_GAME_PAGINATION_LINIT,
        page: 1,
      },
      ContentCardsType.Game,
    );

    if (response.success) {
      this.setGameContentCards(gameContentCardsAdapter(response.data.items));
    }

    this.setFetchedGames(true);
  }

  public async fetchPlayerContentCards() {
    this.setFetchedPlayers(false);

    const response = await this.contentCardsService.fetchContentCards<IPlayerContentResponse[]>(
      {
        limit: HOME_PLAYERS_PAGINATION_LINIT,
        page: 1,
      },
      ContentCardsType.Player,
    );

    if (response.success) {
      this.setPlayerContentCards(playerContentCardsAdapter(response.data.items));
    }

    this.setFetchedPlayers(true);
  }

  private leaveFromGameContentCardsRoom() {
    const gameIds = this.gameContentCards.map((content) => content.gameId);

    gameIds.forEach((gameId) => {
      const roomName = `games:id:${gameId}:content-card`;

      this.ioStore.socket.emit('leave', { room: roomName });
    });
  }

  private leaveFromPlayerContentCardsRoom() {
    const items = this.playerContentCards.map((content) => ({
      gameId: content.gameId,
      playerSlug: content.slug,
    }));

    items.forEach((item) => {
      const roomName = `games:id:${item.gameId}:players:slug:${item.playerSlug}:content-card`;

      this.ioStore.socket.emit('leave', { room: roomName });
    });
  }

  private updateRealtimeConnections = () => {
    if (this.isEnabledRealtimeConnections) {
      this.joinToGameContentCardsRoom();
      this.joinToPlayerContentCardsRoom();
    } else {
      this.leaveFromGameContentCardsRoom();
      this.leaveFromPlayerContentCardsRoom();
    }
  };

  public reset() {
    this.leaveFromGameContentCardsRoom();
    this.leaveFromPlayerContentCardsRoom();
    this.setPlayerContentCards([]);
    this.setGameContentCards([]);
  }
}
