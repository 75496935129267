import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ArenaRoute } from 'routes/arena/arena.route';
import { EmptyArenaRoute } from 'routes/arena/empty-arena.route';
import { ChatRoute } from 'routes/chat/chat.route';
import { ContactUsRoute } from 'routes/contact-us/contact-us.route';
import { ExploreRoute } from 'routes/explore/explore.route';
import { FollowingRoute } from 'routes/followings/followings.route';
import { HomeRoute } from 'routes/home/home.route';
import { FeedParentRoute } from 'routes/home/home-feed/feed-parent/feed-parent.route';
import { HeadlinesFeedRoute } from 'routes/home/home-feed/headlines-feed.route';
import { HomeFeedRoute } from 'routes/home/home-feed/home-feed.route';
import { PostDetailedRoute } from 'routes/home/home-feed/home-feed-post/home-feed-post.route';
import { HomeScoresRoute } from 'routes/home/home-scores/home-scores.route';
import { HomeStandingsRoute } from 'routes/home/home-standings/home-standings.route';
import { HomeStatsRoute } from 'routes/home/home-stats/home-stats.route';
import { HomeGlobalStatsPlayersRoute } from 'routes/home/home-stats/home-stats-players/home-global-stats-players/home-global-stats-players.route';
import { HomeStatsPlayersRoute } from 'routes/home/home-stats/home-stats-players/home-stats-players.route';
import { HomeGlobalStatsTeamsRoute } from 'routes/home/home-stats/home-stats-teams/home-global-stats-teams/home-global-stats-teams.route';
import { HomeStatsTeamsRoute } from 'routes/home/home-stats/home-stats-teams/home-stats-teams.route';
import { InboxRoute } from 'routes/inbox/inbox.route';
import { PlayerPerformanceRoute } from 'routes/player-performance/player-performance.route';
import { PlayerProfileRoute } from 'routes/player-profile/player-profile.route';
import { PlayerProfileFeedRoute } from 'routes/player-profile/player-profile-feed/player-profile-feed.route';
import { PlayerProfileGamesRoute } from 'routes/player-profile/player-profile-games/player-profile-games.route';
import { PlayerProfileOverviewRoute } from 'routes/player-profile/player-profile-overview/player-profile-overview.route';
import { PlayerProfileSplitsRoute } from 'routes/player-profile/player-profile-splits/player-profile-splits.route';
import { PlayerProfileStatsRoute } from 'routes/player-profile/player-profile-stats/player-profile-stats.route';
import { PlayerRankingsRoute } from 'routes/player-rankings/player-rankings.route';
import { PlayerRankingsDetailsRoute } from 'routes/player-rankings-details/player-rankings-details.route';
import { PrivacyPolicyRoute } from 'routes/privacy-policy/privacy-policy.route';
import { PrivateRoute } from 'routes/private-route.component';
import { ProfileRoute } from 'routes/profile/profile.route';
import { RootRoute } from 'routes/root/root.route';
import { SearchRoute } from 'routes/search/search.route';
import { SettingsRoute } from 'routes/settings/settings.route';
import { TeamRoute } from 'routes/team/team.route';
import { TeamFeedRoute } from 'routes/team/team-feed/team-feed.route';
import { TeamProfileRoute } from 'routes/team/team-profile/team-profile.route';
import { TeamScoresRoute } from 'routes/team/team-scores/team-scores.route';
import { TeamStatsRoute } from 'routes/team/team-stats/team-stats.route';
import { TermsOfUseRoute } from 'routes/terms-of-use/terms-of-use.route';
import { UserAgreementRoute } from 'routes/user-agreement/user-agreement.route';

import { ErrorLayout } from 'components/errors/error-layout.component';

import * as paths from './paths.constants';

export const router = createBrowserRouter([
  {
    path: paths.ROOT,
    element: <RootRoute />,
    errorElement: <ErrorLayout />,
    children: [
      {
        index: true,
        element: <Navigate replace to={paths.HOME} />,
      },
      {
        path: paths.HOME,
        element: <HomeRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.HOME_FEED} />,
          },
          {
            path: paths.HOME_FEED,
            element: <FeedParentRoute />,
            children: [
              {
                index: true,
                element: <HomeFeedRoute />,
              },
              {
                path: paths.HOME_FEED_POST,
                element: <PostDetailedRoute />,
              },
            ],
          },
          {
            path: paths.HOME_HEADLINES,
            element: <FeedParentRoute />,
            children: [
              {
                index: true,
                element: <HeadlinesFeedRoute />,
              },
            ],
          },
          {
            path: paths.HOME_SCORES,
            element: <HomeScoresRoute />,
          },
          {
            path: paths.HOME_STATS,
            element: <HomeStatsRoute />,
            children: [
              {
                index: true,
                element: <Navigate replace to={paths.HOME_STATS_PLAYERS} />,
              },
              {
                path: paths.HOME_STATS_TEAMS,
                element: <HomeStatsTeamsRoute />,
              },
              {
                path: paths.HOME_STATS_TEAMS_ALL,
                element: <HomeGlobalStatsTeamsRoute />,
              },
              {
                path: paths.HOME_STATS_PLAYERS,
                element: <HomeStatsPlayersRoute />,
              },
              {
                path: paths.HOME_STATS_PLAYERS_ALL,
                element: <HomeGlobalStatsPlayersRoute />,
              },
            ],
          },
          {
            path: paths.HOME_STANDINGS,
            element: <HomeStandingsRoute />,
          },
        ],
      },
      {
        path: paths.TEAM,
        element: <TeamRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.FEED_PART} />,
          },
          {
            path: paths.TEAM_FEED,
            children: [
              {
                index: true,
                element: <TeamFeedRoute />,
              },
              {
                path: paths.TEAM_FEED_POST,
                element: <PostDetailedRoute />,
              },
            ],
          },
          {
            path: paths.TEAM_SCORES,
            element: <TeamScoresRoute />,
          },
          {
            path: paths.TEAM_PROFILE,
            element: <TeamProfileRoute />,
          },
          {
            path: paths.TEAM_STATS,
            element: <TeamStatsRoute />,
          },
        ],
      },
      {
        path: paths.PROFILE,
        element: (
          <PrivateRoute>
            <ProfileRoute />
          </PrivateRoute>
        ),
      },
      {
        path: paths.PLAYER_PROFILE,
        element: <PlayerProfileRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.FEED_PART} />,
          },
          {
            path: paths.PLAYER_PROFILE_FEED,
            children: [
              {
                index: true,
                element: <PlayerProfileFeedRoute />,
              },
              {
                path: paths.PLAYER_PROFILE_FEED_POST,
                element: <PostDetailedRoute />,
              },
            ],
          },
          {
            path: paths.PLAYER_PROFILE_GAMES,
            element: <PlayerProfileGamesRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_STATS,
            element: <PlayerProfileStatsRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_SPLITS,
            element: <PlayerProfileSplitsRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_OVERFIEW,
            element: <PlayerProfileOverviewRoute />,
          },
        ],
      },
      {
        path: paths.PLAYER_PERFORMANCE,
        element: <PlayerPerformanceRoute />,
      },
      {
        path: paths.EXPLORE,
        element: <ExploreRoute />,
      },
      {
        path: paths.CHAT,
        element: <ChatRoute />,
      },
      {
        path: paths.INBOX,
        element: <InboxRoute />,
      },
      {
        path: paths.SETTINGS,
        element: <SettingsRoute />,
      },
      {
        path: paths.ARENA,
        element: <EmptyArenaRoute />,
      },
      {
        path: paths.ARENA_GAME,
        element: <ArenaRoute />,
      },
      {
        path: paths.PRIVACY_POLICY,
        element: <PrivacyPolicyRoute />,
      },
      {
        path: paths.TERMS_OF_USE,
        element: <TermsOfUseRoute />,
      },
      {
        path: paths.USER_AGREEMENT,
        element: <UserAgreementRoute />,
      },
      {
        path: paths.CONTACT_US,
        element: <ContactUsRoute />,
      },
      {
        path: paths.FOLLOWING,
        element: <FollowingRoute />,
      },
      {
        path: paths.SEARCH,
        element: <SearchRoute />,
      },
      {
        path: paths.PLAYERS_RANKINGS,
        element: <PlayerRankingsRoute />,
      },
      {
        path: paths.PLAYERS_RANKINGS_DETAILS,
        element: <PlayerRankingsDetailsRoute />,
      },
    ],
  },
]);
