import { inject, injectable } from 'inversify';
import {
  action,
  autorun,
  comparer,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from 'mobx';

import { FeedFiltersService } from 'services/feed-filters/feed-filters.service';
import { LocalDatabaseService } from 'services/local-database/local-database.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { allFiltersAdapter } from 'stores/feed-filters/adapters/filters-adapter.util';
import { createFiltersForCategory } from 'stores/feed-filters/helpers/communities.util';
import {
  ICategoryFilters,
  ISingleCategoryRecord,
  ISingleCategoryStructureItem,
} from 'stores/feed-filters/interfaces/feed-filters.interface';
import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';

import { feedFiltersLogger } from 'loggers/feed-filters.logger';

import { FeedTypes } from './enums/feed-types.enum';
import { HEADLINES_FEED_FILTERS_KEY, HOME_FEED_FILTERS_KEY } from './feed-filters.config';

@injectable()
export class FeedFiltersStore extends ApiConnectedStore {
  public filtersForPosts: string[];

  public allTeamsFilters: ICategoryFilters[];

  public currentFilterKey: Maybe<string>;

  public defaultFiltersStructure: Maybe<ISingleCategoryStructureItem[]>;

  public isFilterOpen: boolean;

  public activeFeed: Maybe<FeedTypes>;

  public shouldUpdateFilterKey: boolean;

  constructor(
    @inject(TYPES.AuthStore) private readonly authStore: AuthStore,
    @inject(TYPES.FeedFiltersService) private readonly feedFiltersService: FeedFiltersService,
    @inject(TYPES.LocalDatabaseService) private readonly localDatabaseService: LocalDatabaseService,
    @inject(TYPES.TeamsStore) private readonly teamsStore: TeamsStore,
  ) {
    super();

    this.allTeamsFilters = [];

    this.filtersForPosts = [];

    this.currentFilterKey = null;

    this.activeFeed = null;

    this.isFilterOpen = false;

    this.defaultFiltersStructure = null;

    this.shouldUpdateFilterKey = true;

    makeObservable(this, {
      defaultFiltersStructure: observable,
      allTeamsFilters: observable,
      filtersForPosts: observable,
      currentFilterKey: observable,
      isFilterOpen: observable,
      activeFeed: observable,
      shouldUpdateFilterKey: observable,

      areFiltersInDefaultState: computed,

      setCurrentFilterKey: action.bound,
      setDefaultFiltersStructure: action.bound,
      setIsFilterOpen: action.bound,
      setActiveFeed: action.bound,
      setAllTeamsFilters: action.bound,
      setFiltersForPosts: action.bound,
      setShouldUpdateFilterKey: action.bound,
    });

    reaction(() => this.authStore.isAuthorised, this.handleIsAuthorisedChange);

    reaction(
      () =>
        JSON.stringify([
          this.teamsStore.teams,
          this.authStore.isAuthorised,
          this.defaultFiltersStructure,
        ]),
      this.handleUpdateFilters,
      {
        equals: comparer.shallow,
      },
    );

    reaction(() => `${this.teamsStore.teamId}_${this.activeFeed}`, this.handleUpdateFilterKey);

    reaction(
      () => JSON.stringify([this.allTeamsFilters, this.currentFilterKey]),
      this.prepareFeedFilters,
      {
        equals: comparer.shallow,
      },
    );

    autorun(() => this.handleUpdateFilterKey());

    autorun(() => this.fetchCategories());
  }

  private handleIsAuthorisedChange = async () => {
    if (!this.authStore.isAuthorised) {
      await this.localDatabaseService.clearFiltersTable();
    }
  };

  public handleUpdateFilterKey = () => {
    feedFiltersLogger.info({ msg: 'handle update filter key' });

    if (!this.shouldUpdateFilterKey) {
      feedFiltersLogger.info({ msg: 'filter key should not be updated' });

      this.setShouldUpdateFilterKey(true);

      return;
    }

    if (this.teamsStore.teamId) {
      this.setCurrentFilterKey(this.teamsStore.teamId.toString());

      return;
    }

    if (this.activeFeed === FeedTypes.Headlines) {
      this.setCurrentFilterKey(HEADLINES_FEED_FILTERS_KEY);

      return;
    }

    if (this.activeFeed === FeedTypes.YourFeed) {
      this.setCurrentFilterKey(HOME_FEED_FILTERS_KEY);

      return;
    }

    this.setCurrentFilterKey(null);
  };

  public fetchCategories = async () => {
    const response = await this.feedFiltersService.loadCategories();

    feedFiltersLogger.debug({ msg: 'filter categories response received', response });

    if (response.success) {
      const structure = allFiltersAdapter(response.data);

      this.setDefaultFiltersStructure(structure);
    }
  };

  public handleUpdateFilters = async () => {
    feedFiltersLogger.info({
      msg: 'handle update filters',
      teams: toJS(this.teamsStore.teams),
      defaultsFiltersStructure: toJS(this.defaultFiltersStructure),
    });

    if (this.teamsStore.teams.length && this.defaultFiltersStructure) {
      this.setFetched(false);

      feedFiltersLogger.info({ msg: 'feed filters can be updated' });

      const allFeedFilters: ICategoryFilters[] = [];

      let futureRecordsId = this.teamsStore.teams.map((team) => team.teamId.toString());

      futureRecordsId = [...futureRecordsId, HOME_FEED_FILTERS_KEY, HEADLINES_FEED_FILTERS_KEY];

      for (let i = 0; i < futureRecordsId.length; i++) {
        const key = futureRecordsId[i];

        const existingRecord = await this.localDatabaseService.getFiltersByKey(key);

        const oldFilters = existingRecord?.value ?? [];

        const newRecord = createFiltersForCategory(this.defaultFiltersStructure, oldFilters, key);

        await this.localDatabaseService.updateFilterTableByKey(key, newRecord);

        allFeedFilters.push({ key, items: newRecord });
      }

      this.setAllTeamsFilters(allFeedFilters);
      this.setFetched(true);
    } else {
      feedFiltersLogger.info({ msg: 'feed filters cannot be updated' });
    }
  };

  public updateFiltersByTeamId = async (teamId: string, newFilters: ISingleCategoryRecord[]) => {
    if (this.defaultFiltersStructure) {
      const newRecord = createFiltersForCategory(this.defaultFiltersStructure, newFilters, teamId);

      await this.localDatabaseService.updateFilterTableByKey(teamId, newRecord);
    }
  };

  public async applyNewFilters(value: ISingleCategoryRecord[]) {
    if (this.currentFilterKey) {
      const newAllFilters = [...this.allTeamsFilters];

      const index = newAllFilters.findIndex((item) => item.key === this.currentFilterKey);

      if (index !== -1) {
        newAllFilters[index] = {
          key: this.currentFilterKey,
          items: value,
        };
      }

      this.setAllTeamsFilters(newAllFilters);

      await this.updateFiltersByTeamId(this.currentFilterKey, value);
    }
  }

  public setAllTeamsFilters(allTeamsFilters: ICategoryFilters[]) {
    this.allTeamsFilters = allTeamsFilters;
  }

  public setDefaultFiltersStructure(value: Maybe<ISingleCategoryStructureItem[]>) {
    feedFiltersLogger.debug({
      msg: 'set defaultFiltersStructure',
      value: toJS(value),
      prevValue: toJS(this.defaultFiltersStructure),
    });

    this.defaultFiltersStructure = value;
  }

  public setIsFilterOpen(value: boolean) {
    feedFiltersLogger.debug({
      msg: 'set isFilterOpen',
      value: toJS(value),
      prevValue: toJS(this.isFilterOpen),
    });

    this.isFilterOpen = value;
  }

  public setActiveFeed(value: Maybe<FeedTypes>) {
    feedFiltersLogger.debug({
      msg: 'set activeFeed',
      value: toJS(value),
      prevValue: toJS(this.activeFeed),
    });

    this.activeFeed = value;
  }

  public setCurrentFilterKey(value: Maybe<string>) {
    feedFiltersLogger.debug({
      msg: 'set currentFilterKey',
      value: toJS(value),
      prevValue: toJS(this.currentFilterKey),
    });

    this.currentFilterKey = value;
  }

  public setFiltersForPosts(value: string[]) {
    feedFiltersLogger.debug({
      msg: 'set filtersForPosts',
      value: toJS(value),
      prevValue: toJS(this.filtersForPosts),
    });

    this.filtersForPosts = value;
  }

  public setShouldUpdateFilterKey(value: boolean) {
    feedFiltersLogger.debug({
      msg: 'set ShouldUpdateFilterKey',
      value: toJS(value),
      prevValue: toJS(this.shouldUpdateFilterKey),
    });

    this.shouldUpdateFilterKey = value;
  }

  public get areFiltersInDefaultState(): boolean {
    const defaultFiltersCount = this.defaultFiltersStructure?.reduce(
      (acc, current) => acc + current.tags.length,
      0,
    );

    return defaultFiltersCount === this.filtersForPosts.length;
  }

  public prepareFeedFilters = () => {
    if (this.allTeamsFilters.length && this.currentFilterKey) {
      const filters = this.allTeamsFilters.find((item) => item.key === this.currentFilterKey);

      if (filters) {
        const newFilters: string[] = [];

        filters.items.forEach((category) => {
          if (category.selected) {
            category.tags.forEach((item) => {
              if (item.selected) {
                newFilters.push(item.uuid);
              }
            });
          }
        });

        this.setFiltersForPosts(newFilters);
      } else {
        this.setFiltersForPosts([]);
      }
    }
  };
}
