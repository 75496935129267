import { v4 } from 'uuid';

import { ContentCardsType } from 'services/content-cards/enums/content-cards-type.enum';
import {
  IGameContentDataResponse,
  IPlayerContentDataResponse,
} from 'services/content-cards/interfaces/content-cards-response.interface';
import { POST_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { POSTS_NEWS_IMAGE_PREVIEW } from 'services/http/consts/api-endpoints.constants';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IPostSource, ISourceAuthor } from 'services/posts/interfaces/post-source.interface';
import {
  IPostSourceResponse,
  ISourceAuthorResponse,
} from 'services/posts/interfaces/post-source-response.interface';
import {
  FeedsResponseType,
  IHomeFeedResponse,
  IPlayerFeedResponse,
  IPostResponse,
  IPostVoteResponse,
  IReactionsResponse,
  ITeamFeedResponse,
} from 'services/posts/interfaces/posts-response.interface';

import {
  gameContentAdapter,
  playerContentAdapter,
} from 'stores/content-cards/adapters/publication-adapter.util';
import {
  IGameContent,
  IPlayerContent,
} from 'stores/content-cards/interfaces/content-cards.interface';
import { gifAdapter } from 'stores/posts/adapters/gif-adapter.util';

import { NEGATIVE_REACTIONS, POSITIVE_REACTIONS } from 'configs/reactions.config';
import { formatPastDate } from 'helpers/format/format-past-date.util';
import { formatUsername } from 'helpers/format-username.util';
import { getPath } from 'helpers/get-path.util';

import { IReactions } from 'components/posts/post-card/interfaces/reactions.interface';

import {
  IFeeds,
  IGifAttachment,
  IHomeFeed,
  IIframeAttachment,
  IImageAttachment,
  ILinkAttachment,
  IPlayerFeed,
  IPollAttachment,
  IPost,
  ITeamFeed,
  IVideoAttachment,
} from '../interfaces/post.interface';

import { iframeAdapter } from './iframe-adapter.util';
import { imageAdapter } from './image-adapter.util';
import { linkAdapter } from './link-adapter.util';
import { pollAdapter } from './poll-adapter.util';
import { videoAdapter } from './video-adapter.util';

export function publicationAdapter(postResponse: IPostResponse): IPost {
  const {
    is_bookmarked: isBookmarked,
    title,
    uuid,
    content,
    date_created: timestamp,
    comments_amount: commentsAmount,
    bookmarks_count: bookmarksCount,
    shares_count: sharesCount,
    votes_total: votesTotal,
    user,
    reactions_total: reactionsTotal,
    attachments,
    source,
    content_card: contentCard,
    feeds,
  } = postResponse;

  const convertedImages: Maybe<IImageAttachment[]> = attachments.images.length
    ? attachments.images.map(imageAdapter)
    : null;

  const convertedVideos: Maybe<IVideoAttachment[]> = attachments.videos.length
    ? attachments.videos.map(videoAdapter)
    : null;

  const convertedGifs: Maybe<IGifAttachment[]> = attachments.gifs.length
    ? attachments.gifs.map(gifAdapter)
    : null;

  const convertedIframes: Maybe<IIframeAttachment[]> = attachments.iframes?.length
    ? attachments.iframes.map(iframeAdapter)
    : null;

  const convertedPoll: Maybe<IPollAttachment> = attachments.poll
    ? { ...pollAdapter(attachments.poll) }
    : null;

  const convertedLinks: ILinkAttachment[] = attachments.links.length
    ? attachments.links.map(linkAdapter)
    : [];

  const isExistMediaAttachments: boolean = !!(
    convertedImages?.length ||
    convertedVideos?.length ||
    convertedGifs?.length ||
    convertedIframes?.length
  );

  const filteredLinks: ILinkAttachment[] = convertedLinks.map((item) => {
    const updatedItem = { ...item };

    if (isExistMediaAttachments) {
      updatedItem.isEmbedded = false;
    }

    return updatedItem;
  });

  const embeddedLinks = isExistMediaAttachments
    ? []
    : filteredLinks.filter((item) => item.isEmbedded);

  if (source?.channel === 'tiktok') {
    embeddedLinks.unshift({
      embeddedType: 'tiktok',
      isEmbedded: true,
      url: source.url,
      sourceFaviconUrl: null,
      sourceName: null,
      uuid: v4(),
      title: null,
      previewImageUrl: null,
    });
  }

  const notEmbeddedLinks = isExistMediaAttachments
    ? convertedLinks
    : convertedLinks.filter((item) => !item.isEmbedded);

  return {
    isBookmarked: !!isBookmarked,
    bookmarksCount,
    sharesCount,
    formattedDates: {
      timeOnly: formatPastDate(timestamp, 'timeOnly'),
      relativeLong: formatPastDate(timestamp, 'relativeLong'),
      relativeShort: formatPastDate(timestamp, 'relativeShort'),
      full: formatPastDate(timestamp),
    },
    votesTotal,
    commentsAmount,
    reactionsTotal: prepareReactions(reactionsTotal),
    uuid,
    title,
    content,
    user: {
      smallAvatarUrl: user.small_avatar_url,
      smallThumbnailUrl: user.small_thumbnail_url,
      thumbnailNicknameUrl: user.thumbnail_nickname_url,
      thumbnailLogoUrl: user.thumbnail_logo_url,
      uuid: user.uuid,
      avatarUrl: user.avatar_url,
      name: user.real_name,
      username: `@${user.username}`,
      favoriteTeamId: user.favorite_team_id,
      favoritePlayerId: user.favorite_player_id,
    },
    attachments: {
      images: convertedImages,
      poll: convertedPoll,
      videos: convertedVideos,
      gifs: convertedGifs,
      links: notEmbeddedLinks,
      iframes: convertedIframes,
    },
    embeddedLink: embeddedLinks.length ? embeddedLinks[0] : null,
    source: source ? postSourceAdapter(source, uuid) : null,
    contentCard: contentCard ? contentCardsAdapter(contentCard) : null,
    feeds: feedsAdapter(feeds),
  };
}

function feedsAdapter(feeds: FeedsResponseType): IFeeds {
  const preparedFeeds: IFeeds = {
    teams: [],
    players: [],
    feed: null,
  };

  feeds.forEach((feed) => {
    if (feed.data.type === PostsFeedType.Player) {
      preparedFeeds.players.push(playerFeedAdapter(feed.data));
    }

    if (feed.data.type === PostsFeedType.Team) {
      preparedFeeds.teams.push(teamFeedAdapter(feed.data));
    }

    if (feed.is_default && feed.data.type === PostsFeedType.Player) {
      preparedFeeds.feed = playerFeedAdapter(feed.data);
    }

    if (feed.is_default && feed.data.type === PostsFeedType.Team) {
      preparedFeeds.feed = teamFeedAdapter(feed.data);
    }

    if (feed.is_default && feed.data.type === PostsFeedType.Home) {
      preparedFeeds.feed = homeFeedAdapter(feed.data);
    }
  });

  return preparedFeeds;
}

function playerFeedAdapter(feed: IPlayerFeedResponse): IPlayerFeed {
  return {
    type: PostsFeedType.Player,
    playerId: feed.player_id,
    slug: feed.slug,
    firstname: feed.firstname,
    lastname: feed.lastname,
    smallLogoUrl: feed.small_logo_url,
    mediumLogoUrl: feed.medium_logo_url,
  };
}

function teamFeedAdapter(feed: ITeamFeedResponse): ITeamFeed {
  return {
    type: PostsFeedType.Team,
    teamId: feed.team_id,
    name: feed.name,
    code: feed.code,
    cityName: feed.city_name,
    smallLogoUrl: feed.small_logo_url,
    mediumLogoUrl: feed.medium_logo_url,
  };
}

function homeFeedAdapter(feed: IHomeFeedResponse): IHomeFeed {
  return {
    type: feed.type,
  };
}

function contentCardsAdapter(
  contentData: IPlayerContentDataResponse | IGameContentDataResponse,
): Maybe<IPlayerContent | IGameContent> {
  if (contentData.type === ContentCardsType.Player) {
    return playerContentAdapter(contentData);
  }

  if (contentData.type === ContentCardsType.Game) {
    return gameContentAdapter(contentData);
  }

  return null;
}

function postSourceAuthorAdapter(authorResponse: ISourceAuthorResponse): ISourceAuthor {
  const { name, username, profile_image_url: profileImage } = authorResponse;

  return {
    name,
    username: username ? formatUsername(username) : null,
    profileImage,
  };
}

function postSourceAdapter(postSourceResponse: IPostSourceResponse, postId: string): IPostSource {
  const { channel, favicon, type, url, has_show_more: hasShowMore, author } = postSourceResponse;

  return {
    channel,
    favicon,
    type,
    url,
    imagePreview: getPath(POSTS_NEWS_IMAGE_PREVIEW, {
      [POST_ID_API_PARAM]: postId,
    }),
    hasShowMore,
    author: author ? postSourceAuthorAdapter(author) : null,
  };
}

export function reactionsAdapter(data: IPostVoteResponse) {
  return {
    reactionsTotal: prepareReactions(data.reactions_total),
    votesTotal: data.votes_total,
  };
}

export function getReactionHtml(unicode: string, reactions: typeof POSITIVE_REACTIONS) {
  const reaction = reactions.find((item) => item.unicode === unicode);

  if (reaction) {
    return reaction.htmlValue;
  }

  return '';
}

export function prepareReactions(data: IReactionsResponse): IReactions {
  const positiveReactions = data.up_vote.map((item) => {
    return {
      unicode: item.reaction,
      htmlValue: getReactionHtml(item.reaction, POSITIVE_REACTIONS),
      count: item.count,
      isSelected: item.is_selected,
    };
  });

  const negativeReactions = data.down_vote.map((item) => {
    return {
      unicode: item.reaction,
      htmlValue: getReactionHtml(item.reaction, NEGATIVE_REACTIONS),
      count: item.count,
      isSelected: item.is_selected,
    };
  });

  return {
    upVote: positiveReactions,
    downVote: negativeReactions,
  };
}
