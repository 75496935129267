import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IPostCreateParams } from 'services/posts/interfaces/create-post-payload.interface';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { ContentCardsStore } from 'stores/content-cards/content-cards.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { HeadlinesStore } from 'stores/headlines/headlines.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { PlayerStore } from 'stores/player/player.store';
import { IInteractiveMediaAttachment, IPost } from 'stores/posts/interfaces/post.interface';
import { NBA_FEED_ID } from 'stores/posts/posts.config';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useGaButtonCustomTap } from 'hooks/use-ga-button-custom-tap';
import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { postsLogger } from 'loggers/posts.logger';

import { IAuthor, ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import { PostCreateModal } from 'components/posts/post-create-modal/post-create-modal.component';
import { PostsFeed } from 'components/posts/posts-feed/posts-feed.component';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import {
  FloatingButton,
  FloatingButtonTheme,
} from 'components/ui/floating-button/floating-button.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import { ISharePayload } from '../interfaces/share-payload.interface';

export const PostsFeedContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const contentCardsStore = useInjection<ContentCardsStore>(TYPES.ContentCardsStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const headlinesStore = useInjection<HeadlinesStore>(TYPES.HeadlinesStore);

  const navigate = useNavigate();
  const { isNativeApp } = useMainProvider();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isCreatePostDisabled, setIsCreatePostDisabled] = useState(false);
  const [teamsOptions, setTeamsOptions] = useState<ISelectOption[]>([]);
  const [sharePublicationData, setSharePublicationData] = useState<Maybe<IPost>>(null);

  const { googleAnalyticsHomeFeed, googleAnalyticsTeamFeed } = useGaScreenCustomView();
  const { shareByNativeModal } = useNativeShare();
  const { googleAnalyticsButtonTap } = useGaButtonCustomTap();

  const params = useParams<{
    [path.TEAM_ID_PARAM]: string;
  }>();

  const { teamId } = params;

  const getTeamId = useMemo(() => {
    if (teamId) {
      return Number(teamId);
    }

    return null;
  }, [teamId]);

  const playerSlug = useMemo(() => {
    if (playerStore.playerDetails) {
      return playerStore.playerDetails.slug;
    }

    return null;
  }, [playerStore.playerDetails]);

  useToggleIgnoredTouchClass(postsStore.fetching);

  const activeFeed = useMemo(() => feedFiltersStore.activeFeed, [feedFiltersStore.activeFeed]);

  const isBottomLoading = useMemo(() => {
    if (
      activeFeed === FeedTypes.YourFeed &&
      !contentCardsStore.fetchedGames &&
      !contentCardsStore.fetchedPlayers
    ) {
      return false;
    }

    return !!postsStore.fetchingState && postsStore.isLoadMore;
  }, [
    activeFeed,
    postsStore.fetchingState,
    postsStore.isLoadMore,
    contentCardsStore.fetchedGames,
    contentCardsStore.fetchedPlayers,
  ]);

  const handleCreatePostModalClose = useCallback(() => {
    postsStore.setIsPostCreationModalVisible(false);
    postsStore.setPostVideo(null);
  }, [postsStore]);

  const navigateToTeamFeed = useCallback(
    (id: string) => {
      if (!teamId && !activeFeed) {
        return;
      }

      if (NBA_FEED_ID === id) {
        navigate(getPath(path.HOME_FEED));
      } else {
        navigate(
          getPath(path.TEAM, {
            [path.TEAM_ID_PARAM]: id,
          }),
        );
      }
    },
    [teamId, activeFeed, navigate],
  );

  const handleCreatePostClick = useCallback(
    async (post: IPostCreateParams) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.CreatePostSubmit,
          team: teamsStatsStore.team?.name,
        },
      });

      if (!authStore.triggerAuthorisationCheck()) {
        return;
      }

      if (teamsStore.isUserJoinedToTeam(post.feedToPublish)) {
        teamsStore.setJoinModalVisible(true);

        return;
      }

      setIsCreatePostDisabled(true);

      const isPostCreated = await postsStore.createPost(post);

      if (isPostCreated) {
        postsStore.setIsPostCreationModalVisible(false);
        navigateToTeamFeed(post.feedToPublish);
      }

      setIsCreatePostDisabled(false);
    },
    [authStore, postsStore, teamsStore, teamsStatsStore.team, navigateToTeamFeed],
  );

  const handleLoadMore = useCallback(() => {
    postsStore.fetchToLoadMorePosts();
  }, [postsStore]);

  const handleDeletePost = useCallback(
    async (id: string, postTeamId: Maybe<number>) => {
      const isAuth = authStore.triggerAuthorisationCheck();

      if (isAuth) {
        await postsStore.deletePost({ postId: id, teamId: postTeamId });
        await bookmarksStore.refresh();
      }
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handleTogglePostToBookmark = useCallback(
    async (postId: string, postTeamId?: number, playerId?: number) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      if (postTeamId) {
        await postsStore.toggleTeamPostBookmark({ postId, teamId: postTeamId });
      } else if (playerId) {
        await postsStore.togglePlayerPostBookmark({ postId, playerId });
      } else {
        await postsStore.togglePostBookmark({ id: postId });
      }

      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleShowShareModal = useCallback(
    async (payload: ISharePayload) => {
      await shareStore.fetchSharePublicationData(
        payload.postId,
        undefined,
        payload.teamId,
        payload.playerId,
      );

      if (shareStore.shareData) {
        postsStore.updatePostShareCount(payload.postId, shareStore.shareData.count);
      }

      if (isNativeApp && shareStore.shareData?.type === SharedType.Post) {
        shareByNativeModal(
          shareStore.shareData.link,
          shareStore.shareData.title || metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
        return;
      }

      if (!isNativeApp) {
        const currentPost = postsStore.getPostById(payload.postId);

        setSharePublicationData(currentPost);
        shareStore.setIsShareModalVisible(true);
      }
    },
    [isNativeApp, postsStore, shareStore, metaTagsStore.metaTags, shareByNativeModal],
  );

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
    setSharePublicationData(null);
  }, [shareStore]);

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      if (!authStore.triggerAuthorisationCheck()) {
        if (teamId && !teamsStore.isFollowedTeam) {
          teamsStore.setJoinModalVisible(true);
        }

        return;
      }

      await postsStore.postPollVote(pollData);
      await bookmarksStore.refresh();
    },
    [authStore, postsStore, bookmarksStore, teamsStore, teamId],
  );

  const handlePollExpired = useCallback(
    async (postId: string) => {
      if (!authStore.triggerAuthorisationCheck()) {
        return;
      }

      await postsStore.asyncUpdatePostById({
        postId,
        teamId: getTeamId,
        playerId: playerStore.playerDetails?.id || null,
      });

      await bookmarksStore.refresh();
    },
    [authStore, postsStore, bookmarksStore, getTeamId, playerStore.playerDetails],
  );

  const handleVotePost = useCallback(
    async (
      postId: string,
      reaction: string,
      type: ReactionDirectionType,
      teamIdParam?: number,
      playerId?: number,
    ) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      if (playerId) {
        if (type === 'up') {
          await postsStore.playerPostVoteUp({
            postId,
            reaction,
            playerId,
          });
        }

        if (type === 'down') {
          await postsStore.playerPostVoteDown({
            postId,
            reaction,
            playerId,
          });
        }

        return;
      }

      if (teamIdParam) {
        const teamEntry = teamsStore.teams.find((team) => team.teamId === teamIdParam);

        if (teamEntry?.joined) {
          if (type === 'up') {
            await postsStore.teamPostVoteUp({
              postId,
              reaction,
              teamId: teamIdParam,
            });
          }

          if (type === 'down') {
            await postsStore.teamPostVoteDown({
              postId,
              reaction,
              teamId: teamIdParam,
            });
          }
        } else {
          teamsStore.setJoinModalVisible(true);
        }

        return;
      }

      if (type === 'up') {
        await postsStore.postVoteUp({
          postId,
          reaction,
        });
      }

      if (type === 'down') {
        await postsStore.postVoteDown({
          postId,
          reaction,
        });
      }
    },
    [authStore, postsStore, teamsStore],
  );

  const handleOpenPreview = useCallback(
    (
      author: IAuthor,
      slides: IInteractiveMediaAttachment[],
      date: string,
      attachmentId: number,
    ) => {
      const watermarkEntity = teamsStatsStore.team
        ? { name: teamsStatsStore.team.nickname, logoUrl: teamsStatsStore.team.mediumLogoUrl }
        : null;

      galleryStore.setGalleryAttachments(slides);
      galleryStore.setCurrentAttachmentId(attachmentId);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(watermarkEntity);
    },
    [galleryStore, teamsStatsStore.team],
  );

  const handleResetCurrentPostId = useCallback(
    (postId: Maybe<string>) => {
      postsStore.setCurrentPostId(postId);
      feedFiltersStore.setShouldUpdateFilterKey(true);
    },
    [feedFiltersStore, postsStore],
  );

  const handleUploadVideo = useCallback(
    (file: File) => {
      postsLogger.debug({ msg: 'passed chosen video file', file });
      postsStore.uploadVideoForPost(file);
    },
    [postsStore],
  );

  const handleRemoveVideo = useCallback(() => {
    postsStore.setPostVideo(null);
  }, [postsStore]);

  const handleVideoError = useCallback(
    (message: string) => {
      postsStore.setErrors([
        {
          message,
        },
      ]);
    },
    [postsStore],
  );

  const handleGetFavoriteTeam = useCallback(
    (id: Maybe<number>): Maybe<ITeamsStats> => {
      return teamsStatsStore.findTeamById(id);
    },
    [teamsStatsStore],
  );

  const handleOpenFilters = useCallback(() => {
    feedFiltersStore.setIsFilterOpen(true);
    googleAnalyticsButtonTap(ButtonTapsEnum.FilterIconClick);
  }, [feedFiltersStore, googleAnalyticsButtonTap]);

  const handleGetFavoritePlayer = useCallback(
    (id: Maybe<number>): Maybe<IPlayerStats> => {
      return teamsStatsStore.findPlayerById(id);
    },
    [teamsStatsStore],
  );

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(path.PLAYER_PROFILE, { [path.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRemovePostFromEntries = useCallback(
    (postId: string) => {
      if (!postsStore.isSubmittedContentReport) {
        return;
      }

      postsStore.setSubmittedContentReport(false);
      postsStore.removePostFromEntries(postId);
    },
    [postsStore],
  );

  const handleReportSubmit = useCallback(
    async (postId: string, reasonId: number, teamIdParam?: number, playerId?: number) => {
      if (playerId) {
        return postsStore.sendPlayerPostReport(playerId, postId, reasonId);
      }

      if (teamIdParam) {
        return postsStore.sendTeamPostReport(teamIdParam, postId, reasonId);
      }

      return postsStore.sendPostReport(postId, reasonId);
    },
    [postsStore],
  );

  const handlePostCreationModalOpen = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.CreatePost,
        team: teamsStatsStore.team?.name || 'null',
      },
    });

    if (!authStore.triggerAuthorisationCheck()) {
      return;
    }

    if (!postsStore.postTags.length) {
      postsStore.getPostTags();
    }

    postsStore.setIsPostCreationModalVisible(true);
  }, [postsStore, authStore, teamsStatsStore]);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      postsStore.forceFetchToRefreshFeed();
      contentCardsStore.refreshContentCards();
    }
  }, [layoutStore.isPulledRefresher, postsStore, contentCardsStore]);

  useEffect(() => {
    const teamsSelectorOptions = teamsStore.teams.map((team) => ({
      label: team.name,
      value: team.teamId.toString(),
    }));

    teamsSelectorOptions.push({ label: 'NBA Feed', value: NBA_FEED_ID });

    setTeamsOptions(teamsSelectorOptions);
  }, [teamsStore.teams]);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileFeed);
    }
  }, [teamId, googleAnalyticsTeamFeed]);

  useEffect(() => {
    if (!teamId && layoutStore.isFirstTimeIntroShown && !authStore.authMode) {
      let screen = null;

      if (activeFeed === FeedTypes.YourFeed) {
        screen = ScreenNamesEnum.HomeFeedYourFeed;
      }

      if (screen) {
        googleAnalyticsHomeFeed(screen);
      }
    }
  }, [
    teamId,
    activeFeed,
    layoutStore.isFirstTimeIntroShown,
    authStore.authMode,
    googleAnalyticsHomeFeed,
  ]);

  useEffect(() => {
    if (postsStore.isPostCreationModalVisible) {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.CreatPost,
          team: teamsStatsStore.team?.name || 'null',
        },
      });
    }
  }, [postsStore.isPostCreationModalVisible, teamsStatsStore.team]);

  return (
    <>
      <PostsFeed
        feedsTheme={PostCardFeedsTheme.Collapse}
        headlines={headlinesStore.shortcutHeadlines}
        playerSlug={playerSlug}
        playerContentCards={contentCardsStore.playerContentCards}
        gameContentCards={contentCardsStore.gameContentCards}
        reportReasons={applicationStore.reportReasons}
        isPulledRefresher={layoutStore.isPulledRefresher}
        userId={authStore?.userMe?.id || null}
        hasMore={!postsStore.fetchingState && !postsStore.isLastPageState}
        entries={postsStore.posts}
        team={teamsStatsStore.team}
        fetched={!!postsStore.fetchedState}
        fetching={!!postsStore.fetchingState}
        visitedPostId={postsStore.currentPostId}
        activeFeed={feedFiltersStore.activeFeed}
        onOpenPreview={handleOpenPreview}
        onVotePost={handleVotePost}
        onLoadMore={handleLoadMore}
        onTogglePostToBookmarks={handleTogglePostToBookmark}
        onShareClick={handleShowShareModal}
        onPollVote={handlePollVote}
        onPollExpired={handlePollExpired}
        onDeletePost={handleDeletePost}
        onOpenFilters={handleOpenFilters}
        onResetCurrentPostId={handleResetCurrentPostId}
        onGetFavoriteTeam={handleGetFavoriteTeam}
        onGetFavoritePlayer={handleGetFavoritePlayer}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
        onSendReport={handleReportSubmit}
        onCloseReportsPopup={handleRemovePostFromEntries}
        triggerAuthorisationCheck={authStore.triggerAuthorisationCheck}
      />
      <Loader isShow={isBottomLoading} isLocal />
      {activeFeed !== FeedTypes.Player && (
        <PostCreateModal
          convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
          isFetching={postsStore.processingCreatePost}
          isPostVideoLoading={postsStore.isPostVideoLoading}
          postVideo={postsStore.postVideo}
          onUploadVideo={handleUploadVideo}
          onRemoveVideo={handleRemoveVideo}
          onClose={handleCreatePostModalClose}
          isVisible={postsStore.isPostCreationModalVisible}
          onPostCreate={handleCreatePostClick}
          isCreateDisabled={isCreatePostDisabled}
          teamsList={teamsOptions}
          currentFeedId={teamId || NBA_FEED_ID}
          tagsList={postsStore.postTags}
          onVideoError={handleVideoError}
        />
      )}
      {!isNativeApp && sharePublicationData && shareStore.shareData?.type === SharedType.Post && (
        <ShareModal
          id={sharePublicationData.uuid}
          author={sharePublicationData.user}
          onClose={handleResetSharing}
          onCopyLink={handleCopySharedLink}
          shareContent={{
            pollOptions: shareStore.shareData.pollOptions,
            title: shareStore.shareData.title,
            attachments: shareStore.shareData.attachmentCounts,
            dateCreated: sharePublicationData.formattedDates.relativeLong,
            content: shareStore.shareData.content,
          }}
          visible={shareStore.isShareModalVisible}
        />
      )}
      {activeFeed !== FeedTypes.Player && !isDesktopPlus && (
        <FloatingButton
          theme={FloatingButtonTheme.PostCreation}
          hasSmartBanner={layoutStore.isDisplayedSmartBanner}
          onClick={handlePostCreationModalOpen}
        />
      )}
    </>
  );
});
