import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  COMMENT_ID_API_PARAM,
  PLAYER_ID_API_PARAM,
  TEAM_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { HttpOptionsType } from 'services/http/interfaces/http-options.interface';

import {
  IPostVoteDataPayload,
  VideoUploadPayloadType,
} from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';
import { SortEnum } from 'stores/posts/posts.config';
import { ShareResponse } from 'stores/share/types/share-response.type';

import { TYPES } from 'configs/di-types.config';
import { POLL_ID_PARAM, POLL_OPTION_ID_PARAM, POST_ID_PARAM } from 'routes/paths.constants';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import {
  ADD_POST_TO_BOOKMARK,
  DELETE_POST,
  PLAYER_POST,
  PLAYER_POSTS_BOOKMARK,
  PLAYER_POSTS_COMMENT_SHARE,
  PLAYER_POSTS_SHARE,
  PLAYER_POSTS_VOTE_DOWN,
  PLAYER_POSTS_VOTE_UP,
  POST_POLL_VOTE,
  POST_TAGS,
  POST_VOTE_DOWN,
  POST_VOTE_UP,
  POSTS_TOP,
  SHARE_POST,
  SHARE_POST_COMMENT,
  SINGLE_POST,
  SINGLE_TEAM_POST,
  TEAM_POSTS,
  TEAM_POSTS_BOOKMARK,
  TEAM_POSTS_COMMENT_SHARE,
  TEAM_POSTS_DELETE,
  TEAM_POSTS_SHARE,
  TEAM_POSTS_VOTE_DOWN,
  TEAM_POSTS_VOTE_UP,
  VIDEOS_UPLOAD,
} from '../http/consts/api-endpoints.constants';

import {
  IDeletePostPayload,
  IPostCreatePayload,
  IPostsSearchParams,
  ISharePlayerPostCommentPayload,
  ISharePlayerPostPayload,
  ISharePostCommentPayload,
  ISharePostPayload,
  IShareTeamPostCommentPayload,
  IShareTeamPostPayload,
  ITogglePlayerPostBookmarkPayload,
  ITogglePostBookmarkPayload,
  IToggleTeamPostBookmarkPayload,
} from './interfaces/create-post-payload.interface';
import { IPollAttachmentResponse } from './interfaces/post-attachments-response.interface';
import { IPostTagResponse } from './interfaces/post-tag-response.interface';
import {
  IDeletePostResponse,
  IPostResponse,
  IPostVoteResponse,
  ITogglePostBookmarkResponse,
  IVideoResponse,
} from './interfaces/posts-response.interface';

@injectable()
export class PostsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchPosts(
    pagination: IPagination,
    tags: string,
  ): Promise<IResponse<IPostsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IPostsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort: SortEnum.NEW,
    };

    if (tags.length) {
      paramsObject.tags = tags;
    }

    return this.httpService.request<IPostsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '5',
      },
      url: POSTS_TOP,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async fetchTeamPosts(
    teamId: number,
    pagination: IPagination,
    tags: string,
  ): Promise<IResponse<IPostsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IPostsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort: SortEnum.NEW,
    };

    if (tags.length) {
      paramsObject.tags = tags;
    }

    return this.httpService.request<IPostsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '4',
      },
      url: TEAM_POSTS,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public async fetchPostById(
    postId: string,
    commentsSort?: SortEnum,
  ): Promise<IResponse<IPostResponse>> {
    const requestOptions: HttpOptionsType = {
      method: 'GET',
      url: SINGLE_POST,
      headers: {
        'X-Version': '4',
      },
      routeParams: { [POST_ID_PARAM]: postId },
    };

    if (commentsSort) {
      requestOptions.params = new URLSearchParams({ sort: commentsSort });
    }

    return this.httpService.request<IPostResponse>(requestOptions);
  }

  public async fetchTeamPostById(
    teamId: number,
    postId: string,
  ): Promise<IResponse<IPostResponse>> {
    const requestOptions: HttpOptionsType = {
      method: 'GET',
      url: SINGLE_TEAM_POST,
      headers: {
        'X-Version': '4',
      },
      routeParams: { [POST_ID_PARAM]: postId, [TEAM_ID_API_PARAM]: teamId.toString() },
    };

    return this.httpService.request<IPostResponse>(requestOptions);
  }

  public async fetchPlayerPostById(
    playerId: number,
    postId: string,
  ): Promise<IResponse<IPostResponse>> {
    const requestOptions: HttpOptionsType = {
      method: 'GET',
      url: PLAYER_POST,
      headers: {
        'X-Version': '4',
      },
      routeParams: { [POST_ID_PARAM]: postId, [PLAYER_ID_API_PARAM]: playerId.toString() },
    };

    return this.httpService.request<IPostResponse>(requestOptions);
  }

  public async createPost(payload: IPostCreatePayload): Promise<IResponse<IPostResponse>> {
    return this.httpService.request<IPostResponse, IPostCreatePayload>({
      method: 'POST',
      headers: {
        'X-Version': '2',
      },
      url: POSTS_TOP,
      body: payload,
    });
  }

  public async createTeamPost(
    teamId: string,
    payload: IPostCreatePayload,
  ): Promise<IResponse<IPostResponse>> {
    return this.httpService.request<IPostResponse, IPostCreatePayload>({
      method: 'POST',
      headers: {
        'X-Version': '2',
      },
      url: TEAM_POSTS,
      body: payload,
      routeParams: { [TEAM_ID_API_PARAM]: teamId },
    });
  }

  public async togglePostBookmark(
    payload: ITogglePostBookmarkPayload,
  ): Promise<IResponse<ITogglePostBookmarkResponse>> {
    return this.httpService.request<ITogglePostBookmarkResponse, ITogglePostBookmarkPayload>({
      method: 'POST',
      url: ADD_POST_TO_BOOKMARK,
      routeParams: { [POST_ID_PARAM]: payload.id },
    });
  }

  public async toggleTeamPostBookmark(
    payload: IToggleTeamPostBookmarkPayload,
  ): Promise<IResponse<ITogglePostBookmarkResponse>> {
    return this.httpService.request<ITogglePostBookmarkResponse, IToggleTeamPostBookmarkPayload>({
      method: 'POST',
      url: TEAM_POSTS_BOOKMARK,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [TEAM_ID_API_PARAM]: payload.teamId.toString(),
      },
    });
  }

  public async togglePlayerPostBookmark(
    payload: ITogglePlayerPostBookmarkPayload,
  ): Promise<IResponse<ITogglePostBookmarkResponse>> {
    return this.httpService.request<ITogglePostBookmarkResponse, ITogglePlayerPostBookmarkPayload>({
      method: 'POST',
      url: PLAYER_POSTS_BOOKMARK,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
      },
    });
  }

  public async deletePost(payload: IDeletePostPayload): Promise<IResponse<IDeletePostResponse>> {
    return this.httpService.request<IDeletePostResponse, IDeletePostPayload>({
      method: 'DELETE',
      url: DELETE_POST,
      routeParams: { [POST_ID_PARAM]: payload.postId },
    });
  }

  public async deleteTeamPost(
    payload: IDeletePostPayload,
  ): Promise<IResponse<IDeletePostResponse>> {
    const routeParams: Record<string, string> = {
      [POST_ID_PARAM]: payload.postId,
    };

    if (payload.teamId) {
      routeParams[TEAM_ID_API_PARAM] = payload.teamId.toString();
    }

    return this.httpService.request<IDeletePostResponse, IDeletePostPayload>({
      method: 'DELETE',
      url: TEAM_POSTS_DELETE,
      routeParams,
    });
  }

  public async sharePost(payload: ISharePostPayload): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePostPayload>({
      method: 'POST',
      url: SHARE_POST,
      routeParams: { [POST_ID_PARAM]: payload.id },
    });
  }

  public async shareTeamPost(payload: IShareTeamPostPayload): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, IShareTeamPostPayload>({
      method: 'POST',
      url: TEAM_POSTS_SHARE,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [TEAM_ID_API_PARAM]: payload.teamId.toString(),
      },
    });
  }

  public async sharePlayerPost(
    payload: ISharePlayerPostPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePlayerPostPayload>({
      method: 'POST',
      url: PLAYER_POSTS_SHARE,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
      },
    });
  }

  public async sharePostComment(
    payload: ISharePostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePostCommentPayload>({
      method: 'POST',
      url: SHARE_POST_COMMENT,
      routeParams: { [POST_ID_PARAM]: payload.postId, [COMMENT_ID_API_PARAM]: payload.commentId },
    });
  }

  public async shareTeamPostComment(
    payload: IShareTeamPostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, IShareTeamPostCommentPayload>({
      method: 'POST',
      url: TEAM_POSTS_COMMENT_SHARE,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [COMMENT_ID_API_PARAM]: payload.commentId,
        [TEAM_ID_API_PARAM]: payload.teamId.toString(),
      },
    });
  }

  public async sharePlayerPostComment(
    payload: ISharePlayerPostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePlayerPostCommentPayload>({
      method: 'POST',
      url: PLAYER_POSTS_COMMENT_SHARE,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [COMMENT_ID_API_PARAM]: payload.commentId,
        [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
      },
    });
  }

  public async postPollVote(payload: IPollVoteData): Promise<IResponse<IPollAttachmentResponse>> {
    return this.httpService.request<IPollAttachmentResponse, IPollVoteData>({
      method: 'POST',
      url: POST_POLL_VOTE,
      routeParams: { [POLL_ID_PARAM]: payload.pollId, [POLL_OPTION_ID_PARAM]: payload.answerId },
    });
  }

  public async postVoteUp(
    postId: string,
    payload: IPostVoteDataPayload,
  ): Promise<IResponse<IPostVoteResponse>> {
    return this.httpService.request<IPostVoteResponse, IPostVoteDataPayload>({
      method: 'POST',
      url: POST_VOTE_UP,
      body: payload,
      routeParams: { [POST_ID_PARAM]: postId },
    });
  }

  public async postVoteDown(
    postId: string,
    payload: IPostVoteDataPayload,
  ): Promise<IResponse<IPostVoteResponse>> {
    return this.httpService.request<IPostVoteResponse, IPostVoteDataPayload>({
      method: 'POST',
      url: POST_VOTE_DOWN,
      body: payload,
      routeParams: { [POST_ID_PARAM]: postId },
    });
  }

  public async teamPostVoteUp(
    postId: string,
    teamId: number,
    payload: IPostVoteDataPayload,
  ): Promise<IResponse<IPostVoteResponse>> {
    return this.httpService.request<IPostVoteResponse, IPostVoteDataPayload>({
      method: 'POST',
      url: TEAM_POSTS_VOTE_UP,
      body: payload,
      routeParams: { [POST_ID_PARAM]: postId, [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public async teamPostVoteDown(
    postId: string,
    teamId: number,
    payload: IPostVoteDataPayload,
  ): Promise<IResponse<IPostVoteResponse>> {
    return this.httpService.request<IPostVoteResponse, IPostVoteDataPayload>({
      method: 'POST',
      url: TEAM_POSTS_VOTE_DOWN,
      body: payload,
      routeParams: { [POST_ID_PARAM]: postId, [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public async playerPostVoteUp(
    postId: string,
    playerId: number,
    payload: IPostVoteDataPayload,
  ): Promise<IResponse<IPostVoteResponse>> {
    return this.httpService.request<IPostVoteResponse, IPostVoteDataPayload>({
      method: 'POST',
      url: PLAYER_POSTS_VOTE_UP,
      body: payload,
      routeParams: { [POST_ID_PARAM]: postId, [PLAYER_ID_API_PARAM]: playerId.toString() },
    });
  }

  public async playerPostVoteDown(
    postId: string,
    playerId: number,
    payload: IPostVoteDataPayload,
  ): Promise<IResponse<IPostVoteResponse>> {
    return this.httpService.request<IPostVoteResponse, IPostVoteDataPayload>({
      method: 'POST',
      url: PLAYER_POSTS_VOTE_DOWN,
      body: payload,
      routeParams: { [POST_ID_PARAM]: postId, [PLAYER_ID_API_PARAM]: playerId.toString() },
    });
  }

  public async uploadVideo(payload: VideoUploadPayloadType): Promise<IResponse<IVideoResponse>> {
    return this.httpService.request<IVideoResponse, VideoUploadPayloadType>({
      method: 'POST',
      url: VIDEOS_UPLOAD,
      body: payload,
      isMultipart: true,
    });
  }

  public async fetchTags(): Promise<IResponse<IPostTagResponse[]>> {
    return this.httpService.request<IPostTagResponse[]>({
      method: 'GET',
      url: POST_TAGS,
    });
  }
}
