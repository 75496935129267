import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';

import { TYPES } from 'configs/di-types.config';
import * as path from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionLink } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

import { getTeamRouteTabs } from './utils/get-team-route-tabs.util';

export const FeedTabsContainer: FC = observer(() => {
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);

  const params = useParams<{ [path.TEAM_ID_PARAM]: string; [path.POST_ID_PARAM]: string }>();

  const { teamId } = params;

  const homeTabs = useMemo<ITab<BarActionLink>[]>(() => {
    let homeFeedPath = path.HOME_FEED;

    if (feedFiltersStore.activeFeed === FeedTypes.Headlines) {
      homeFeedPath = path.HOME_HEADLINES;
    }

    return [
      {
        id: 'home_feed',
        title: 'Feed',
        action: {
          type: BarAction.Link,
          path: homeFeedPath,
        },
      },
      {
        id: 'home_scores',
        title: 'Scores',
        action: { type: BarAction.Link, path: path.HOME_SCORES },
      },
      {
        id: 'home_stats',
        title: 'Stats',
        action: { type: BarAction.Link, path: path.HOME_STATS },
      },
      {
        id: 'home_standings',
        title: 'Standings',
        action: { type: BarAction.Link, path: path.HOME_STANDINGS },
      },
    ];
  }, [feedFiltersStore.activeFeed]);

  const tabs = useMemo<ITab<BarActionLink>[]>(
    () => (teamId ? getTeamRouteTabs(teamId) : homeTabs),
    [teamId, homeTabs],
  );

  useLayoutEntity({
    type: LayoutEntity.Tabs,
    value: tabs,
  });

  return null;
});
