import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import cn from 'classnames';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';

import { ISliderItem } from 'containers/feed-filters/feed.config';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './feeds-select-slider.module.less';

export interface IFeedsSelectSliderProps {
  items: ISliderItem[];
  fullWidth: boolean;
  isForFeed?: boolean;
  onItemClick: (action: BarActionType, value: FeedTypes) => void;
  onNavigateFavorites?: () => void;
}

export const FeedsSelectSlider: FC<IFeedsSelectSliderProps> = memo(
  (props: IFeedsSelectSliderProps) => {
    const { items, fullWidth, isForFeed = false, onItemClick, onNavigateFavorites } = props;

    const { pathname, state } = useLocation();

    const feedsSelectSliderWrapperClasses = useMemo(
      () =>
        cn(styles.FeedsSelectSlider, {
          [styles['FeedsSelectSlider--full-width']]: fullWidth,
        }),
      [fullWidth],
    );

    const getItemClasses = useCallback(
      (action: BarActionType) =>
        cn(styles.FeedsSelectSlider__Item, {
          [styles['FeedsSelectSlider__Item--selected']]:
            action.type === BarAction.Link && pathname.includes(action.path),
        }),
      [pathname],
    );

    const getFeedsSliderIconClasses = useCallback(
      (action: BarActionType) =>
        cn(styles.FeedsSliderIcon, {
          [styles['FeedsSliderIcon--selected']]:
            action.type === BarAction.Link && pathname.includes(action.path),
          [styles['FeedsSliderIcon--animated']]: state === 'navigated',
        }),
      [pathname, state],
    );

    const handleItemClick = useCallback(
      (action: BarActionType, value: FeedTypes) => () => {
        onItemClick(action, value);

        window.history.replaceState({}, '');
      },
      [onItemClick],
    );

    const handleAddIconClick = useCallback(
      (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        onNavigateFavorites?.();
      },
      [onNavigateFavorites],
    );

    return (
      <div className={feedsSelectSliderWrapperClasses}>
        {items.map((item) => (
          <button
            key={item.label}
            className={getItemClasses(item.action)}
            onClick={handleItemClick(item.action, item.value)}
          >
            {item.label}
            {item.value === FeedTypes.YourFeed && isForFeed && (
              <span
                role="presentation"
                className={getFeedsSliderIconClasses(item.action)}
                onClick={handleAddIconClick}
              >
                <span className={styles.FeedsSliderIcon__Inner}>
                  <IconFont name={IconFontName.Add} size={IconFontSize.ExtraSmall} />
                </span>
              </span>
            )}
          </button>
        ))}
      </div>
    );
  },
);
