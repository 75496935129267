import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';

import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';

export interface ISliderItem {
  label: string;
  action: BarActionType;
  value: FeedTypes;
}

export const FEED_SLIDER_ITEMS: ISliderItem[] = [
  {
    label: 'Your Feed',
    action: {
      type: BarAction.Link,
      path: paths.HOME_FEED,
    },
    value: FeedTypes.YourFeed,
  },
  {
    label: 'Headlines',
    action: {
      type: BarAction.Link,
      path: paths.HOME_HEADLINES,
    },
    value: FeedTypes.Headlines,
  },
];

export const FILTER_SLIDER_ITEMS: ISliderItem[] = [
  {
    label: 'Your Feed',
    action: {
      type: BarAction.Link,
      path: paths.HOME_FEED,
    },
    value: FeedTypes.YourFeed,
  },
];
