import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { FollowStore } from 'stores/follow/follow.store';
import { GameStore } from 'stores/game/game.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';
import { ScoreboardStore } from 'stores/scoreboard/scoreboard.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { HEADLINES_FILTER_NAME, NBA_FILTER_NAME } from 'configs/google-analytics.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as path from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { BarActionType } from 'components/bars/bar-action.type';
import { SidebarDesktop } from 'components/sidebars/sidebar-desktop/sidebar-desktop.component';
import { SidebarDrawer } from 'components/sidebars/sidebar-drawer/sidebar-drawer.component';

interface ISidebarContainerProps {
  onSidebarItemClick: (action: BarActionType) => void;
}

export const SidebarContainer: FC<ISidebarContainerProps> = observer((props) => {
  const { onSidebarItemClick } = props;

  const teamStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const scoreboardStore = useInjection<ScoreboardStore>(TYPES.ScoreboardStore);

  const location = useLocation();
  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const activePath = useMemo(() => location.pathname, [location.pathname]);

  const handleSidebarItemClick = useCallback(
    (action: BarActionType) => {
      onSidebarItemClick(action);

      feedFiltersStore.setShouldUpdateFilterKey(true);
      layoutStore.setMobileSidebarOpen(false);
      scoreboardStore.fetchCurrentDate();
    },
    [scoreboardStore, layoutStore, feedFiltersStore, onSidebarItemClick],
  );

  const handleScrollTopClick = useCallback(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

    topElement?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const homeLink = useMemo(() => {
    if (location.pathname === path.HOME_HEADLINES) {
      return { path: path.HOME_HEADLINES, feed: HEADLINES_FILTER_NAME };
    }

    return {
      path: path.HOME_FEED,
      feed: NBA_FILTER_NAME,
    };
  }, [location.pathname]);

  const handleLogoClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.HomeFeed,
        feed: homeLink.feed,
      },
    });

    navigate(homeLink.path);

    teamsStore.setTeamId(null);
    postsStore.setCurrentPostId(null);
    feedFiltersStore.setShouldUpdateFilterKey(true);
    layoutStore.setIsScrollToTopEvent(true);

    handleScrollTopClick();
  }, [
    homeLink,
    teamsStore,
    postsStore,
    feedFiltersStore,
    layoutStore,
    navigate,
    handleScrollTopClick,
  ]);

  const handleTeamFollow = useCallback(async () => {
    if (authStore.triggerAuthorisationCheck()) {
      await teamsStore.followTeam();
    }
  }, [authStore, teamsStore]);

  const handleCloseLeftSidePanel = useCallback(async () => {
    if (layoutStore.isMobileSidebarOpen) {
      layoutStore.setMobileSidebarOpen(false);
    }
    if (layoutStore.isSidePanelOpen) {
      layoutStore.setSidePanelActiveTab(null);
    }
  }, [layoutStore]);

  const teamName = useMemo(() => teamStatsStore.team?.name || '', [teamStatsStore.team]);

  if (isDesktopPlus) {
    return (
      <SidebarDesktop
        firstNextNotFinishedGameId={gameStore.firstNextNotFinishedGameId}
        followingPlayers={followStore.followingPlayers}
        teamId={teamsStore.teamId}
        teamName={teamName}
        isAuthorized={authStore.isAuthorised}
        activePath={activePath}
        followedTeams={teamsStore.getTeamsBySubscription.joined}
        exploreTeams={teamsStore.getTeamsBySubscription.explore}
        onClick={handleSidebarItemClick}
        onLogoClick={handleLogoClick}
        onTeamFollow={handleTeamFollow}
      />
    );
  }

  return (
    <SidebarDrawer
      followingPlayers={followStore.followingPlayers}
      teamId={teamsStore.teamId}
      teamName={teamName}
      isAuthorized={authStore.isAuthorised}
      isPulledRefresher={layoutStore.isPulledRefresher}
      activePath={location.pathname}
      teams={teamsStore.getTeamsBySubscription}
      onClick={handleSidebarItemClick}
      onCloseLeftSidePanel={handleCloseLeftSidePanel}
    />
  );
});
