import { Device } from '@capacitor/device';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { TPlatformName } from 'types/platform-name.type';

import { GA_TAG_ID } from 'configs/environment.config';

import { googleAnalyticsLogger } from 'loggers/google-analytics.logger';

import { IEventPayload } from './interfaces/event-payload.interface';

class GoogleAnalyticService {
  private ga: Gtag.Gtag;

  private platform: Maybe<TPlatformName>;

  private userCustomId: Maybe<string>;

  constructor() {
    this.ga = gtag;

    this.platform = 'web';

    this.userCustomId = null;

    this.initialisation();
  }

  private async initialisation() {
    const deviceInfo = await Device.getInfo();

    this.platform = deviceInfo.platform;

    if (!this.isApplication) {
      this.initializeGoogleTag();
    }
  }

  private initializeGoogleTag() {
    const options = {
      debug_mode: true,
    };

    this.ga('js', new Date());
    this.ga('config', GA_TAG_ID, options);
  }

  private get isApplication() {
    return this.platform === 'android' || this.platform === 'ios';
  }

  public setCommonParams(userCustomId: Maybe<string>) {
    this.userCustomId = userCustomId;
  }

  public setScreenAppName(title: string) {
    googleAnalyticsLogger.debug({ msg: 'screen name (app)', title });

    FirebaseAnalytics.setCurrentScreen({
      screenName: title,
    });
  }

  public event(payload: IEventPayload) {
    const params = {
      ...payload.eventParams,
    };

    if (this.userCustomId) {
      params.user_custom_id = this.userCustomId;
    }

    googleAnalyticsLogger.debug({ msg: payload.eventName, ...params });

    if (this.isApplication) {
      FirebaseAnalytics.logEvent({
        name: payload.eventName,
        params,
      });
    } else {
      this.ga('event', payload.eventName, params);
    }
  }
}

export default new GoogleAnalyticService();
