import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';
import { GameStore } from 'stores/game/game.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { ScoreboardStore } from 'stores/scoreboard/scoreboard.store';
import { SplitsGamesStore } from 'stores/splits-games/splits-games.store';
import { TeamsStore } from 'stores/teams/teams.store';

import { DeeplinkBannerContainer } from 'containers/deeplink-banner/deeplink-banner.container';
import { HeaderContainer } from 'containers/header/header.container';
import { NavTabsContainer } from 'containers/nav-tabs/nav-tabs.container';
import { SidebarContainer } from 'containers/sidebar/sidebar.container';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { CAPACITOR_ENV } from 'configs/environment.config';
import { HEADLINES_FILTER_NAME, NBA_FILTER_NAME } from 'configs/google-analytics.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import {
  CSS_VAR_BACKDROP_OPACITY,
  CSS_VAR_BACKDROP_VISIBILITY,
  CSS_VAR_CONTAINER,
  MAX_BACKDROP_OPACITY,
  MAX_LEFT_SIDEBAR_WIDTH,
} from 'configs/swipe-navigation.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { ARENA_PART, PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';
import { useScrollContainer } from 'hooks/use-scroll-container';
import { useScrollToTop } from 'hooks/use-scroll-to-top';
import { useViewportHeight } from 'hooks/use-viewport-height';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType, BarMultipleAction } from 'components/bars/bar-action.type';
import { BarActionsListType } from 'components/bars/bar-actions-list-type.enum';
import { IBarItem } from 'components/bars/bar-item.interface';
import { BottomBarMobile } from 'components/bars/bottom-bar-mobile/bottom-bar-mobile.component';
import { Refresher } from 'components/refresher/refresher.component';
import { SwipeNavigationType } from 'components/swipe-navigation/enums/swipe-navigation-type.enum';
import { SwipeNavigation } from 'components/swipe-navigation/swipe-navigation.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './layout.module.less';

interface ILayoutContainerProps {
  children: ReactNode;
}

export const LayoutContainer: FC<ILayoutContainerProps> = observer(
  (props: PropsWithChildren<ILayoutContainerProps>) => {
    const { children } = props;

    const params = useParams<{
      [paths.TEAM_ID_PARAM]: string;
      [paths.POST_ID_PARAM]: string;
      [PLAYER_SLUG_PARAM]: string;
    }>();

    const { teamId, postId, playerSlug } = params;

    const authStore = useInjection<AuthStore>(TYPES.AuthStore);
    const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
    const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
    const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
    const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
    const gameStore = useInjection<GameStore>(TYPES.GameStore);
    const splitsGamesStore = useInjection<SplitsGamesStore>(TYPES.SplitsGamesStore);
    const scoreboardStore = useInjection<ScoreboardStore>(TYPES.ScoreboardStore);

    const navigate = useNavigate();
    const location = useLocation();

    const { isNativeApp } = useMainProvider();
    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

    const scrollElementRef = useRef<Maybe<HTMLElement>>(null);

    const getTeamId = useMemo(() => {
      if (teamId) {
        return Number(teamId);
      }

      return null;
    }, [teamId]);

    useEffect(() => {
      if (location.pathname.includes(ARENA_PART)) {
        return;
      }

      if (!playerSlug && (splitsGamesStore.selectedTeamId || splitsGamesStore.splitSize)) {
        splitsGamesStore.setSplitSize(null);
        splitsGamesStore.setSelectedTeamId(null);
      }
    }, [
      splitsGamesStore,
      location.pathname,
      splitsGamesStore.selectedTeamId,
      splitsGamesStore.splitSize,
      playerSlug,
    ]);

    useScrollToTop<HTMLElement>(scrollElementRef);
    useViewportHeight();
    useKeyboardTrigger(isNativeApp);

    const { isFixedSliderToggle, isHiddenSliderToggle, handleScrollContainer } = useScrollContainer(
      !!layoutStore.tabs?.length,
      location.pathname,
    );

    useEffect(() => {
      layoutStore.setHiddenSliderToggle(isHiddenSliderToggle);
    }, [isHiddenSliderToggle, layoutStore]);

    useEffect(() => {
      layoutStore.setFixedSliderToggle(isFixedSliderToggle);
    }, [isFixedSliderToggle, layoutStore]);

    useEffect(() => {
      if (getTeamId) {
        teamsStore.setTeamId(getTeamId);
      } else {
        teamsStore.reset();
      }
    }, [getTeamId, teamsStore]);

    const nextGameUrl = useMemo(() => {
      if (gameStore.firstNextNotFinishedGameId) {
        return getPath(paths.ARENA_GAME, {
          [paths.GAME_ID_PARAM]: String(gameStore.firstNextNotFinishedGameId),
        });
      }

      return getPath(paths.ARENA);
    }, [gameStore.firstNextNotFinishedGameId]);

    const homeLink = useMemo(() => {
      if (location.pathname === paths.HOME_HEADLINES) {
        return { path: paths.HOME_HEADLINES, feed: HEADLINES_FILTER_NAME };
      }

      return {
        path: paths.HOME_FEED,
        feed: NBA_FILTER_NAME,
      };
    }, [location.pathname]);

    const bottomBarItems = useMemo<IBarItem<BarMultipleAction<BarActionsListType>>[]>(
      () => [
        {
          name: 'Home',
          action: {
            type: BarAction.Link,
            path: homeLink.path,
            payload: BarActionsListType.Home,
          },
          isDisabled: false,
          icon: { default: IconFontName.Home, active: IconFontName.HomeFilled },
        },
        {
          name: 'Arena',
          action: { type: BarAction.Link, path: nextGameUrl, payload: BarActionsListType.Arena },
          icon: { default: IconFontName.Arena, active: IconFontName.ArenaFilled },
        },
        {
          name: 'Rankings',
          isDisabled: false,
          action: {
            type: BarAction.Link,
            path: paths.PLAYERS_RANKINGS,
            payload: BarActionsListType.Rankings,
          },
          icon: { default: IconFontName.Rating, active: IconFontName.RatingFilled },
        },
        {
          isDisabled: false,
          name: authStore.isAuthorised ? 'Profile' : 'Sign Up',
          action: authStore.isAuthorised
            ? { type: BarAction.Link, path: paths.PROFILE, payload: BarActionsListType.Profile }
            : { type: BarAction.Click, payload: BarActionsListType.Profile },
          icon: { default: IconFontName.Profile, active: IconFontName.ProfileFilled },
        },
      ],
      [authStore.isAuthorised, nextGameUrl, homeLink],
    );

    useEffect(() => {
      if (isDesktopPlus) {
        layoutStore.setMobileSidebarOpen(false);
      }
    }, [isDesktopPlus, layoutStore]);

    const handleScrollTopClick = useCallback(() => {
      const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

      topElement?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleClickActions = useCallback(
      (payloadType: string) => {
        if (payloadType === BarActionsListType.Profile) {
          authStore.setAuthMode(AuthMode.SignUp);
        }
      },
      [authStore],
    );

    const handleGaBottomBarButtonTap = useCallback(
      (barActionType: string) => {
        if (BarActionsListType.Home === barActionType) {
          GoogleAnalyticService.event({
            eventName: 'button_custom_tap',
            eventParams: {
              button_tap_type: ButtonTapsEnum.HomeFeed,
              feed: homeLink.feed,
            },
          });
        }
        if (BarActionsListType.Arena === barActionType) {
          GoogleAnalyticService.event({
            eventName: 'button_custom_tap',
            eventParams: {
              button_tap_type: ButtonTapsEnum.Arena,
            },
          });
        }
        if (BarActionsListType.Rankings === barActionType) {
          GoogleAnalyticService.event({
            eventName: 'button_custom_tap',
            eventParams: {
              button_tap_type: ButtonTapsEnum.Rankings,
            },
          });
        }
        if (BarActionsListType.Profile === barActionType) {
          GoogleAnalyticService.event({
            eventName: 'button_custom_tap',
            eventParams: {
              button_tap_type: ButtonTapsEnum.UserProfile,
            },
          });
        }
      },
      [homeLink],
    );

    const handleItemClick = useCallback(
      (action: BarActionType) => {
        if (action.payload) {
          handleGaBottomBarButtonTap(action.payload);
        }
        if (action.type === BarAction.Link) {
          navigate(action.path);
        }
        if (action.type === BarAction.Click && action.payload) {
          handleClickActions(action.payload);
        }

        handleScrollTopClick();
        layoutStore.setMobileSidebarOpen(false);
        scoreboardStore.fetchCurrentDate();
      },
      [
        scoreboardStore,
        layoutStore,
        navigate,
        handleClickActions,
        handleScrollTopClick,
        handleGaBottomBarButtonTap,
      ],
    );

    const handleSidebarItemClick = useCallback(
      (action: BarActionType) => {
        if (action.type === BarAction.Link) {
          navigate(action.path);
        }
        if (action.type === BarAction.Click && action.payload === 'contact') {
          navigate(paths.CONTACT_US);
        }
      },
      [navigate],
    );

    const handleCloseSidebar = useCallback(() => {
      if (layoutStore.isMobileSidebarOpen) {
        layoutStore.setMobileSidebarOpen(false);
      }
      if (layoutStore.isSidePanelOpen) {
        layoutStore.setSidePanelActiveTab(null);
      }
    }, [layoutStore]);

    const classNames = useMemo<string>(
      () =>
        cn(styles.Layout, {
          [styles['Layout--shifted']]: layoutStore.isMobileSidebarOpen,
        }),
      [layoutStore.isMobileSidebarOpen],
    );

    useEffect(() => {
      metaTagsStore.setRoute(location.pathname);
    }, [location.pathname, metaTagsStore]);

    useEffect(() => {
      if ((layoutStore.isMobileSidebarOpen || layoutStore.isSidePanelOpen) && !isDesktopPlus) {
        document.documentElement.style.setProperty(
          CSS_VAR_BACKDROP_OPACITY,
          `${MAX_BACKDROP_OPACITY}`,
        );
        document.documentElement.style.setProperty(CSS_VAR_BACKDROP_VISIBILITY, 'visible');
      } else {
        document.documentElement.style.removeProperty(CSS_VAR_BACKDROP_OPACITY);
        document.documentElement.style.removeProperty(CSS_VAR_BACKDROP_VISIBILITY);
      }
    }, [layoutStore.isMobileSidebarOpen, layoutStore.isSidePanelOpen, isDesktopPlus]);

    useEffect(() => {
      if (layoutStore.isMobileSidebarOpen) {
        document.documentElement.style.setProperty(CSS_VAR_CONTAINER, MAX_LEFT_SIDEBAR_WIDTH);
      } else {
        document.documentElement.style.removeProperty(CSS_VAR_CONTAINER);
      }
    }, [layoutStore.isMobileSidebarOpen]);

    useEffect(() => {
      const title = metaTagsStore.metaTags?.title.replace('Digits - ', '');

      if (title && applicationStore.isNativeApp) {
        GoogleAnalyticService.setScreenAppName(title);
      }
    }, [metaTagsStore.metaTags?.title, applicationStore.isNativeApp, authStore]);

    useEffect(() => {
      const isNativeAppInDevMode = applicationStore.isNativeApp && CAPACITOR_ENV === 'dev';

      if (isNativeAppInDevMode) {
        const marker = document.querySelector<HTMLDivElement>('#marker');

        if (marker) {
          marker.style.display = 'block';
        }
      }
    }, [applicationStore.isNativeApp]);

    return (
      <>
        <Helmet>
          <title>{metaTagsStore.metaTags?.title}</title>
        </Helmet>
        <SwipeNavigation
          tabs={layoutStore.tabs}
          isAllowedSwipeNavBack={layoutStore.isAllowedSwipeNavBack}
          isLeftSidebarOpen={layoutStore.isMobileSidebarOpen}
          setLeftSidebarOpen={layoutStore.setMobileSidebarOpen}
          setDisabledScroll={layoutStore.setDisabledScroll}
          swipeNavType={SwipeNavigationType.TopLevel}
        >
          <div className={classNames}>
            {!layoutStore.isHiddenHeaderMobile && !layoutStore.isHiddenMobileSidebar && (
              <SidebarContainer onSidebarItemClick={handleSidebarItemClick} />
            )}
            <div className={styles.LayoutContentWrapper}>
              {!layoutStore.isHiddenHeaderMobile && (
                <div className={styles.LayoutTopInner}>
                  <HeaderContainer />
                  {(!postId || isDesktopPlus) && <NavTabsContainer />}
                </div>
              )}
              <button
                aria-label="Backdrop"
                onClick={handleCloseSidebar}
                className={styles.Backdrop}
              />
              <Refresher
                ref={scrollElementRef}
                isPulledRefresher={layoutStore.isPulledRefresher}
                isDisabledScroll={layoutStore.isDisabledScroll}
                isRefresherTopPosition={layoutStore.isRefresherTopPosition}
                isEnabledRefresher={layoutStore.isEnabledRefresher}
                isHiddenBottomBar={layoutStore.isHiddenBottomBar}
                isHeaderDisabled={layoutStore.isHiddenHeaderMobile}
                isRemovedSafeAreaTop={layoutStore.isRemovedSafeAreaTop}
                isDesktopPlus={isDesktopPlus}
                isNativeApp={isNativeApp}
                isMobileSidebarOpen={layoutStore.isMobileSidebarOpen}
                isSidePanelOpen={layoutStore.isSidePanelOpen}
                isDisplayedSmartBanner={layoutStore.isDisplayedSmartBanner}
                hasPostId={!!postId}
                hasTabs={!!layoutStore.tabs?.length}
                setPulledRefresher={layoutStore.setPulledRefresher}
                onScrollContainer={handleScrollContainer}
              >
                {children}
              </Refresher>
              {!isDesktopPlus && !layoutStore.isHiddenBottomBar && (
                <div className={styles.Layout__BottomBar}>
                  <BottomBarMobile
                    activePath={location.pathname}
                    items={bottomBarItems}
                    onClick={handleItemClick}
                    userMe={authStore.userMe}
                  />
                </div>
              )}
              <DeeplinkBannerContainer />
            </div>
          </div>
        </SwipeNavigation>
      </>
    );
  },
);
